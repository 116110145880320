import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { MediaContentType } from "../../../store/redux/chat";
import MessageType from "./MessageType";

import "./SendMessage.scss";
import { BrowserView, MobileView } from "react-device-detect";

const SendMessage = ({
  onMessageTypeChange,
  onChangeText,
  value,
  sendMessage,
  attachmentRef,
  addMedia,
}) => {
  const acceptedFileTypes = Object.keys(MediaContentType)
    .map((index) => {
      return MediaContentType[index];
    }, [])
    .join(", ");

  return (
    <>
      {/* <MobileView>
        <MessageType onChange={onMessageTypeChange} />
      </MobileView> */}
      <div className="text-wrapper">
        <input
          name="chat"
          type="text"
          value={value}
          className="text-input"
          placeholder="Start typing..."
          onKeyDown={({ keyCode }) => {
            if (keyCode === 13) sendMessage();
          }}
          onChange={({ target: { value } }) => onChangeText(value)}
        />
        <div className="text-wrapper-row">
          {/* <BrowserView>
            <MessageType onChange={onMessageTypeChange} />
          </BrowserView> */}

          {/* <div
            className="attachment-button"
            onClick={() => {
              attachmentRef.current.click();
            }}
          >
            <FontAwesomeIcon icon={faPaperclip} className="attachment-icon" />
            <input
              ref={attachmentRef}
              type="file"
              hidden
              multiple={false}
              accept={acceptedFileTypes}
              onChange={() => {
                addMedia(attachmentRef.current.files);
              }}
            />
          </div> */}

          <div
            className="send-button"
            onClick={() => {
              sendMessage();
            }}
          >
            <FontAwesomeIcon icon={faPaperPlane} className="send-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMessage;
