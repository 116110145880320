import gql from "graphql-tag";
import { propertyLocation } from "../utils/propertiesKeys";

export const leasesGql = gql`
  query Leases($sort: [LeaseTypeSortEnum], $input: [String]) {
    leases(sort: $sort, statuses: $input) {
      edges {
        node {
          applicationId
          unitId
          amount
          localLease
          status
          leaseFrequency
          startDate
          onBoardScript
          endDate
          documentUrl
          moveInChecklist
          createdAt
          updatedAt
          guarantee
          lgState
          skipGuarantee
          lgExpiryTime
          insurancePolicy
          id
          application {
            id
          }
          unit {
            photos
            bathrooms
            bedrooms
            sqft
            number
            amenities
            id
          }
          location {
            name
            addresses
            walkScore
            photos
            id
            lat
            lng
          }
        }
      }
    }
  }
`;

export const singleLeaseGql = gql`
  query Lease($leaseId: String!) {
    lease(leaseId: $leaseId) {
      edges {
        node {
          amount
          status
          rules
          leaseFrequency
          startDate
          endDate
          documentUrl
          guarantee
          id
          unit {
            number
            id
            unitType {
              id
              type
            }
          }
          unitAmount
          unitQuantity
          petAmount
          petQuantity
          vehicleAmount
          vehicleQuantity
          extLeaseInfo
          deposit
          nonRefundable
          propertyManagers{
            id
            firstName
            lastName
            email
            middleName
            phoneMobile
          }
        }
      }
    }
  }
`;

export const activeLeasesMaintenanceGql = gql`
  query Leases($role: String, $primaryLocationId: String, $status: [String]) {
    leases(
      role: $role
      primaryLocationId: $primaryLocationId
      status: $status
    ) {
      edges {
        node {
          primaryLocation {
            customId
          }
          property {
            location {
              unitNum
            }
          }
        }
      }
    }
  }
`;

export const allLeasesGql = gql`
query {
  leases(filter: {
    allMyLeases: true
  }) {
    edges {
      node {
        property {
          ndbId
          ${propertyLocation}
        }
      }
    }
  }
}
`;

export const createLeaseGql = gql`
  mutation createLease($input: CreateLeaseInput!) {
    createLease(input: $input) {
      leaseInfo {
        created
        ndbId
      }
    }
  }
`;

export const cancelLeaseGql = gql`
  mutation cancelLease($input: CancelLeaseInput!) {
    cancelLease(input: $input) {
      clientMutationId
    }
  }
`;

export const LeaseGuaranteeOptions = gql`
  query rentGuardPrice($leaseId: ID!) {
    rentGuardPrice(leaseId: $leaseId)
  }
`;

export const sendSelectedCoverage = gql`
  mutation orderRentGuard($input: OrderRentGuardMutInput!) {
    orderRentGuard(input: $input) {
      response
    }
  }
`;

export const updateLease = gql`
  mutation updateLease($input: UpdateLeaseInput!) {
    updateLease(input: $input) {
      response
    }
  }
`;

export const LeaseGuaranteeStatus = gql`
  query rentGuardStatus($leaseId: ID!) {
    rentGuardStatus(leaseId: $leaseId)
  }
`;


export const RecipientView = gql`
  mutation recipientView($input: CreateEnvelopeInput!) {
    recipientView(lease: $input) {
      response
      docusign{
        url
      }
    }
  }
`;

export const CreateEnv = gql`
  mutation createEnvelope($input: CreateEnvelopeInput!) {
    envelopeByTemplate(lease: $input) {
      response
      lease{
        accessToken
        tokenType
      }
}
  }
`;
export const actionLocalLease = gql`
  mutation actionLocalLease($input: ActionLocalLeaseInput!) {
    actionLocalLease(input: $input) {
      response
      lease {
        localLease
    }
  }
}
`;