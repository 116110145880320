import React from "react";
import "./NewRenterCarousel.scss";
import { Card, Image } from "semantic-ui-react";

export const Carousel = (props) => {
  const photos = props.photos || props.images;
  const { carouselId } = props;

  return (
    <Card fluid>
      <div id={carouselId} className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="inner-container">
            {photos &&
              photos.map((photo, index) => {
                return (
                  <div
                    key={Math.random()}
                    className={
                      index === 0
                        ? "carousel-item carousel-extend active"
                        : "carousel-item"
                    }
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="carousel-img-container"
                          style={{ height: "320px", background: "#f2fcfb" }}
                        >
                          <Image
                            className="img-property"
                            src={photo.url ? photo.url : photo}
                            alt="Your property"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {photos && photos.length === 0 && (
              <div
                key={Math.random()}
                className={"carousel-item carousel-extend active"}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="carousel-img-container"
                      style={{ height: "320px", background: "#f2fcfb" }}
                    >
                      <Image
                        className="img-property"
                        src={"./assets/img/renter-banner.jpg"}
                        alt="Your property"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href={"#" + carouselId}
          role="button"
          data-slide="prev"
        >
          <i className="batch-icon batch-icon-arrow-left batch-icon-lg" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href={"#" + carouselId}
          role="button"
          data-slide="next"
        >
          <i className="batch-icon batch-icon-arrow-right batch-icon-lg" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </Card>
  );
};
