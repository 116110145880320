import React from "react";
import { Button, Header, Image, Modal, Confirm } from "semantic-ui-react";
import { getClient } from "../../store/auth/init-apollo-googleFn";

import { actionLocalLease } from "../../store/person/leases";
import { LEASE_RENTER_LOCAL, LEASES_URL } from "../../utils";
import get from "lodash/get";

import { getAuthToken } from "../../store/auth/authUtility";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";

const LEASES = getClient(LEASES_URL);
function SelectedLeaseModal(props) {
  const [open, setOpen] = React.useState(false);
  const [decline, onDecline] = React.useState(false);
  const [confirm, onConfirm] = React.useState(false);
  const [pdf, setPDF] = React.useState(false);

  const viewLeaseOffer = (item) => {
    setOpen(true);
    // const tempo = "TGVhc2U6IDYwNw==";
    fetch(LEASE_RENTER_LOCAL + item, {
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setPDF(url);
      })
      .catch((error) => {
        console.error(error);
        // setPreviewLoader(false);
      });
  };

  const actionLease = (item) => {
    try {
      LEASES.mutate({
        mutation: actionLocalLease,
        variables: {
          input: {
            leaseId: props.selectedLeaseId,
            status: item,
          },
        },
      })
        .then((response) => {
          if (response.errors) {
            toastFailMsg(get("response.errors[0].message"));
            onConfirm(false);
          } else {
            toastSuccessMsg("Action sent to Property Manager");
            setOpen(false);
            onConfirm(false);
          }
        })
        .catch((error) => {
          toastFailMsg(parseGraphQLErrors(error));
          onConfirm(false);
        });
    } catch (e) {
      toastFailMsg();
    }
  };

  return (
    <Modal
      className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={
        <button
          className="ui button finish-app"
          onClick={() => viewLeaseOffer(props.selectedLeaseId)}
        >
          View Lease
        </button>
      }
    >
      <Modal.Header>Lease Offer: {props.local&&props.local.status}</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ height: "75vh" }}>
          <object
            data={pdf}
            type="application/pdf"
            width="100%"
            height="100%"
          ></object>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" floated="left" onClick={() => setOpen(false)}>
          Close
        </Button>
        {
          props.local &&
          props.local.status !== "Accepted" &&
          <>
        <Button color="black" onClick={() => onDecline(true)}>
          Decline
        </Button>
        <Button color="green" onClick={() => onConfirm(true)}>
          Accept
        </Button>
        </>
        }

      </Modal.Actions>
      <Confirm
        className="semanticModal"
        open={decline}
        content="By selecting Decline you are choosing to reject the offer. The Property Manager will be able to review your declination and review further options."
        onCancel={() => onDecline(false)}
        onConfirm={() => actionLease("decline")}
      />
      <Confirm
        className="semanticModal"
        content="By selecting Accept you are agreeing to all the terms within the outlined Lease.  Once all parties confirm on this lease the Property Manager will be able to move forward."
        open={confirm}
        onCancel={() => onConfirm(false)}
        onConfirm={() => actionLease("accept")}
      />
    </Modal>
  );
}

export default SelectedLeaseModal;
