import React from "react";
import { Modal } from "semantic-ui-react";
import TourMap from "./TourMap";

function MapItModal({ modalOpen, data, openMapModal }) {
  return (
    <Modal
      size="tiny"
      className="map-it-modal"
      onClose={() => openMapModal()}
      open={modalOpen}
    >
      <TourMap location={data} />
    </Modal>
  );
}

export default MapItModal;
