import React from "react";
import LeaLoader from "./LeaLoader";
import "./Loader.scss";

/**
  Loader was called by previous Quill template, replaced with Semantic Semantic Loader
  Will keep here so we can style our loaders to look the same throughout the app
**/

export function Loader({
  wrapperStyle,
  inTable,
  logoStyle,
  stepper,
  text
}) {
  return (
    <LeaLoader
      wrapperStyle={wrapperStyle}
      logoStyle={logoStyle}
      inTable={inTable}
      stepper={stepper}
      text={text}
    />
  );
}
