import gql from "graphql-tag";

export const personPointsTransactionGql = gql`
  query pointsLedger {
    pointsLedger {
      edges {
        node {
          id
          status
          description
          pointsUsed
          pointsAdded
          pointsBalance
          pointServiceId
          historicalTotal
          customMessage
          createdAt
        }
      }
    }
  }
`;

export const getGiftExchangeHistoryGql = gql`
  {
    getGiftExchangeHistory {
      id
      status
      exchangeType
      giftData
      createdAt
      updatedAt
    }
  }
`;
export const getGiftExchangeTypesGql = gql`
  {
    getExchangeTypes {
      type
      info
    }
  }
`;
export const createGiftExchangeGql = gql`
  mutation createGiftExchange($taken_points: Int!, $exchange_type: String!) {
    createGiftExchange(takenPoints: $taken_points, exchangeType: $exchange_type)
  }
`;