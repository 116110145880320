import React from "react";
import "./Carousel.scss";
import { COMING_SOON } from "../../utils/constants";

export const Carousel = (props) => {
  return (
    <div>
      <div className="carousel-full">
        <div className="carousel-title">
          <h3 className="heading heading-md">Featured Offers</h3>
        </div>
        <div
          id="carousel-full"
          className="carousel slide mb-5"
          data-ride="carousel"
          data-interval="false"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carousel-full"
              data-slide-to="0"
              className="active"
            />
            <li data-target="#carousel-full" data-slide-to="1" />
            <li data-target="#carousel-full" data-slide-to="2" />
          </ol>
          <div className="carousel-inner carousel-extend" role="listbox">
            <div className="carousel-item carousel-extend active">
              <div
                className="row"
                data-toggle="tooltip"
                data-placement="right"
                title={COMING_SOON}
              >
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price points ">$100/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Dank Digs</p>
                      <p className="offer-price">$420/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Dank Digs</p>
                      <p className="offer-price">$420/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Lovely Abode</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img-tall-overflow">
                    {/* <div className="offer-img-overlay">
                      <p className="offer-title">Romantic Villa</p>
                      <p className="offer-price">$420/Night</p>
                      <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>500</div>
                    </div>
                    <hr /> */}
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                    <span className="offer-price">$1,200/Night</span>
                    <div className="offers-txt">
                      <h6>Sky High Hayat Villa</h6>
                      <p>
                        11311 19th Ave SE, everett, Washington DC, 98208, US
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <a
            className="carousel-control-prev trips-prev"
            href="#carousel-full"
            role="button"
            data-slide="prev"
          >
            <i className="batch-icon batch-icon-arrow-left batch-icon-lg" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next trips-next"
            href="#carousel-full"
            role="button"
            data-slide="next"
          >
            <i className="batch-icon batch-icon-arrow-right batch-icon-lg" />
            <span className="sr-only">Next</span>
          </a> */}
        </div>
      </div>

      <div className="carousel-small">
        <div className="carousel-title">
          <h3 className="heading heading-md">Featured Offers</h3>
        </div>
        <div
          id="carousel-small"
          className="carousel slide mb-5"
          data-ride="carousel"
          data-interval="false"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carousel-small"
              data-slide-to="0"
              className="active"
            />
            <li data-target="#carousel-small" data-slide-to="" />
            <li data-target="#carousel-small" data-slide-to="2" />
          </ol>
          <div className="carousel-inner carousel-extend" role="listbox">
            <div className="carousel-item carousel-extend active">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price points ">$100/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Dank Digs</p>
                      <p className="offer-price">$420/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Fancy Place</p>
                      <p className="offer-price">$1,200/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Dank Digs</p>
                      <p className="offer-price">$420/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Fancy Place</p>
                      <p className="offer-price">$1,200/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Lovely Abode</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Nice Hotel</p>
                      <p className="offer-price">$100/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Romantic Villa</p>
                      <p className="offer-price">$420/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-tall-overflow">
                    <div className="offer-img-overlay">
                      <p className="offer-title">Fancy Place</p>
                      <p className="offer-price">$1,200/Night</p>
                      <div className="offer-points">
                        <span className="batch-icon batch-icon-star-alt"></span>
                        500
                      </div>
                    </div>
                    <hr />
                    <img
                      className="img-fluid"
                      src="assets/img/renter-bg.jpg"
                      alt="Product 1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev trips-prev"
          href="#carousel-small"
          role="button"
          data-slide="prev"
        >
          <i className="batch-icon batch-icon-arrow-left batch-icon-lg" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next trips-next"
          href="#carousel-small"
          role="button"
          data-slide="next"
        >
          <i className="batch-icon batch-icon-arrow-right batch-icon-lg" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};
