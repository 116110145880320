import React, { Component } from "react";
import { Carousel } from "../../components/Quill/Carousel";
import "./Trips.scss";
import PropertyCard from "../../components/Property/PropertyCard";
import { PropertyModal } from "../../components/Modals/PropertyModal";
import { FAKE_PROPERTY, COMING_SOON } from "../../utils/constants";
// import { Loader } from "../../components/Loader/Loader";
import { TripsEmpty } from "../../components/EmptyCards/TripsEmpty";
import { qpTooltipPopover } from "../../utils/misc";
import "../../assets/fomantic/dist/semantic.css";
import "../../assets/fomantic/dist/semantic.css";

class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShortLease: true,
      pastShortLeases: true,
      filter: {
        currentShortLease: true,
        pastShortLeases: true,
      },
      modalProperty: FAKE_PROPERTY,
      leases: [],
    };
  }

  componentDidMount() {
    qpTooltipPopover();
    // this.getLeases()
  }

  selectedTrip = (e) => {
    let filter = {};
    switch (e.target.value) {
      case "all":
        filter = {
          currentShortLease: true,
          pastShortLeases: true,
        };
        break;
      case "past":
        filter = {
          pastShortLeases: true,
        };
        break;
      case "current":
        filter = { currentShortLease: true };
        break;
      default:
        filter = {
          currentShortLease: true,
          pastShortLeases: true,
        };
        break;
    }
    this.setState({
      filter: filter,
    });
  };

  setModalProperty = (modalProperty) => this.setState({ modalProperty });

  render() {
    const { modalProperty, leases } = this.state;
    return (
      <main className="main-content renter-trips-wrap" role="main">
        <div className="row">
          <div className="col-md-12">
            <h2>Trips</h2>
            <h5>Here’s the summary of your trips</h5>
            <Carousel />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading heading-md">Past Trips</h3>
          </div>
        </div>
        <div className="row">
          <div
            className="past-trips-grid"
            data-toggle="tooltip"
            data-placement="right"
            title={COMING_SOON}
          >
            {/*using this filter because actual filter below has no data*/}
            {leases.length <= 0 ? (
              <div>
                <TripsEmpty />
              </div>
            ) : (
              leases.map((lease) => {
                const { startDate, endDate, property } = lease.node;
                return (
                  <div key={Math.random()}>
                    <PropertyCard
                      ownedProp={property}
                      withStay={{ startDate, endDate }}
                      setModalProperty={this.setModalProperty}
                      withArrow
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
        {/* eslint-disable*/}
        {/* <div className="row">
          <div className="col-md-12">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <a className="page-link" to="#!">
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" to="#!">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" to="#!">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" to="#!">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" to="#!">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div> */}
        {/*eslint-enable*/}
        <PropertyModal history={this.props.history} property={modalProperty} />
      </main>
    );
  }
}

export default Trips;
