const prodConfig = {
  apiKey: 'AIzaSyAZIQvo58ynQ9TSvmuW_NOlYCZ_q4nSR0Q',
  authDomain: 'leasera-production.firebaseapp.com',
  databaseURL: 'https://leasera-production.firebaseio.com',
  projectId: 'leasera-production',
  storageBucket: 'leasera-production.appspot.com',
  messagingSenderId: '913859279590',
  appId: "1:662469587140:web:63efd01be202870886d991"
};

const WEB_PUSH_NOTIFICATION_KEY_PAIR = {
  dev: "BHzxl-8e3Rd73K8PQYR6LbXZTUoJVaSrMjUPjKXknqBBxTT3cBJx5kZ5VTj9rwL3hWgMzrharUHBRQbMzs-sz6E",
  staging:
    "BPr8TogJICOmLIFVvIpW4J0PMLGhYnIU7_TZWWe7sOoDvEdVm_Cbqeslgl9Ls1aDDyFCjUCqRQ_4KYlM0Hd6Vyo",
  production:
    "BKbeCHHtcDfbqgWEDr4It0B3hKifibvWQAuGhiRJKrZUV1BtWKP6wfWjnfHBqRzWpZY062_qRb3uev5XWZb6IgU",
};

let config = prodConfig;
let vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.production;

// if (process.env.REACT_APP_DEVELOP === "200719") {
//   config = devConfig;
//   vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.dev;
// }

// if (process.env.REACT_APP_STAGING === "staging") {
//   config = stagingConfig;
//   vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.staging;
// }

// if (process.env.REACT_APP_STAGING === "staging") {
//   config = stagingConfig;
//   vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.staging;
// }

if (process.env.REACT_APP_PRODUCTION === "production") {
  config = prodConfig;
  vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.production;
}

export { config, vapidKey };
