import React from "react";
import { useSelector } from "react-redux";
import "./RoomsListFilterTabs.scss";

// const filters = ["All", "Renter", "Team", "Service Professionals", "Homeowner"];
export const ALL = "All";

const RoomsListFilterTabs = ({ onClick, activeFilter, roomList }) => {
  const { chatRoomDetails } = useSelector((store) => store.chat);
  const filters = roomList.reduce(
    (prev, item) => {
      const label = chatRoomDetails[item.chatRoomId]?.role;
      if (label && !prev.includes(label)) {
        prev.push(label);
      }

      return prev;
    },
    [ALL]
  );

  return (
    <div className={`room-list-tabs`}>
      {filters.map((item, index) => (
        <div
          key={index}
          className={`item ${activeFilter === item ? "active" : ""}`}
          onClick={() => {
            onClick(item);
          }}
        >
          <span>{item.toUpperCase()}</span>
        </div>
      ))}
    </div>
  );
};

export default RoomsListFilterTabs;
