const initialState = {
  ndbId: null,
  personId: null,
  locationIds: [],
  fullName: "",
  firstName: "",
  email: "",
  middleName: "",
  lastName: "",
  phoneMobile: "",
  phoneHome: "",
  photoUrl: "",
  photoPath: "",
  dateOfBirth: "",
  role: "",
  avatar: null,
};

export const SET_PERSON_DATA = "SET_PERSON_DATA";
export const RESET_PERSON = "RESET_PERSON";

const personReducer = (state = initialState, { payload, type }) => {
  if (type === SET_PERSON_DATA) {
    return {
      ...state,
      ...payload,
    };
  }

  if (type === RESET_PERSON) {
    return { ...initialState };
  }

  return state;
};

export default personReducer;
