import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuthToken } from "../store/auth";
import { getCookie } from "../store/auth/authUtility";

const PrivateRoute = ({ component: Component, isServiceProf, ...rest }) => {
  const cookie = getCookie();
  const redirectUrl =
    rest.path !== "/dashboard" ? rest.location.pathname : null;
  return (
    <Route
      {...rest}
      render={(props) =>
        Boolean(getAuthToken()) && cookie.renter === "true" ? (
          <Component {...props} role={cookie} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              search: redirectUrl ? `redirectUrl=${redirectUrl}` : undefined,
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
