import React from "react";

import MessageTypeIcon from "./MessageTypeIcon";
import LogBody from "./LogBody";
import Media from "../Media";

const RegularLogView = ({ isSender, body, time, media, messageLabel }) => {
  const timestamp = (
    <div className="time-type-wrapper">
      <MessageTypeIcon type={messageLabel} />
      <div className="time">
        <span>{time}</span>
      </div>
    </div>
  );
  return (
    <>
      {body.length > 0 && (
        <LogBody
          {...{ isSender, messageLabel, type: "regular", media_type: "none" }}
        >
          <div className="body">
            <span>{body}</span>
          </div>
          {timestamp}
        </LogBody>
      )}

      {typeof media === typeof [] &&
        media.map((data, key) => (
          <LogBody
            {...{
              key,
              isSender,
              messageLabel,
              type: "regular",
              media_type: data.content_type,
            }}
          >
            <div className="media">
              <Media key={key} {...data} />
            </div>
            {timestamp}
          </LogBody>
        ))}
    </>
  );
};

export default RegularLogView;
