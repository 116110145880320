import React, { useEffect } from "react";
import { Button, Modal, Grid , Segment, Checkbox, Popup} from "semantic-ui-react";
import { CREATED_AT_DESC, LEASES_URL,PAYMENTS_URL } from "../../utils/constants";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { getEmitter } from "../../utils";
import { leasesGql } from "../../store/person/leases";
import { disableCurrentMt } from "../../utils/common";
import get from "lodash/get";
import Datetime from "react-datetime";
import moment from "moment";
import LeaLoader  from "../Loader/LeaLoader";
import "react-datetime/css/react-datetime.css";
import {
  ConvenienceFees,ConcessionsAmountLease
} from "../../store/person/payment";

const paymentsClient = getClient(PAYMENTS_URL);
const leasesClient = getClient(LEASES_URL);
const eventEmit = getEmitter();
export function AutopaySetup(props) {
  const [open, setOpen] = React.useState(false);
  const [leases, activeLeases] = React.useState(false);
  const [useConcessions, toggleConcessions] = React.useState(false);
  const [concessionsPreMonth, setConcessionsPreMonth] = React.useState(false); 
  const [monthLength, setMonthLength] = React.useState(false);
  const [start, setStart] = React.useState(false);
  const [val, amountVal] = React.useState(false);
  const [concessionsAmount, setConcessionsAmount] = React.useState(false);
  const [ccInfo, setCCInfo] = React.useState(false);
  const [achInfo, setACHInfo] = React.useState(false);

  /* eslint-disable */
  const [end, setEnd] = React.useState(moment(val.endDate).format("L"));
  /* eslint-enable */
  const [Options, setMethods] = React.useState([]);

  const [chosenOptions, setChosenMethods] = React.useState(false);
  const [chosenLease, setChosenLease] = React.useState(false);

  const runCheck = () => {
    if (document.getElementById("lease")) {
      console.log(document.getElementById("lease"))
      const parseVal = document.getElementById("lease").value;
      getPoints(JSON.parse(parseVal))
      return amountVal(JSON.parse(parseVal));
    }
  };

  useEffect(() => {
    eventEmit.on("ScheduleSuccess", () => props.onAutopayOpen(false));
    checkConcessions()
  },[]);

  useEffect(() => {
    checkConcessions()
  },[val]);

  useEffect(() => {
    RecalculateConcessions()
  },[useConcessions,start]);

  const RecalculateConcessions = () => {
    if(!start && useConcessions){
      const monthLength =  moment(val.endDate).diff(moment().add(1, "month"), 'months', true)
      const monthDividedBy = concessionsAmount/monthLength.toFixed(0)
      setMonthLength(monthLength)
      setConcessionsPreMonth(monthDividedBy)
    }
    if(start){
      const monthLength =  moment(val.endDate).diff(moment(start), 'months', true)
      const monthDividedBy = concessionsAmount/monthLength.toFixed(0)
      setMonthLength(monthLength)
      setConcessionsPreMonth(monthDividedBy)
    }
    // console.log(monthLength.toFixed(0), monthDividedBy, concessionsAmount)
  }

  const checkConcessions = () => {
    const leaseId = val.id
    if(leaseId){
      leasesClient
        .query({
          query: ConcessionsAmountLease,
          variables: {
            leaseId: atob(leaseId).substring(atob(leaseId).indexOf(":") + 1)
            },
        })
        .then((res) => {
          const concessions = res.data.concessionsAmountLease.edges
          if(concessions.length !==0){
            setConcessionsAmount(concessions[0].node.concessionsAmount)
          }
          if(concessions.length == 0){
            setConcessionsAmount(false)
          }
        })
        .catch((e) => {
  
        });

    }
  }

  const getLeases = () => {
    props.onAutopayOpen(true);
    toggleConcessions(false)
    setStart(false)
    setConcessionsPreMonth(0)
    
    setConcessionsAmount(false)
    setMethod(props.paymentOptions&& JSON.stringify(props.paymentOptions[0]))
    try {
      leasesClient
        .query({
          query: leasesGql,
          variables: {
            statuses: ["move_in", "move_out", "signed", "created"],
            sort: CREATED_AT_DESC,
          },
        })
        .then((response) => {
          if (!response.error) {
            const leases = get(response, "data.leases.edges", []);
            const currentAuto = [];
            props.autopays.forEach((val) => {
              var part = val.scheduleIdentifier.split("-");
              return currentAuto.push(part[1]);
            });
            const activeLease = leases.filter(
              (lease) =>
                lease.node.status === "move_in" ||
                lease.node.status === "signed" ||
                lease.node.status === "created"
            );
            const currentLease = activeLease.filter(
              (active) => !currentAuto.includes(active.node.unitId)
            );
            activeLeases(currentLease);
            setMethods(props && props.paymentOptions);
            runCheck(leases[0]);
          }
        })
        .catch((error) => {

        });
    } catch (e) {
      console.log(e);
    }
  };

  const validOptions = leases && leases.map((lease) => {
    const leaseDate = new moment(lease.node.endDate).format("L");
    const today = new moment().format("L");
    const validOption = moment(leaseDate).isAfter(today);
    return validOption && lease;
  }).filter(a => a !== false);

  const getPoints = (e) => {
    paymentsClient
      .query({
        query: ConvenienceFees,
        variables: {
            amount: e.amount,
            points: 0
          },
      })
      .then((res) => {
        const constant = res.data.convenienceFees
        // props.setChosenMethod(constant)
        setCCInfo(constant[0])
        setACHInfo(constant[1])
      })
      .catch((e) => {

      });
  };

  const setMethod = (e) => {
    if(e){
      setChosenMethods(JSON.parse(e))

    }
  }

  const triggerMethod = () => {
    getLeases()
    props.onAutopayOpen(true)
  }


  return (
    <Modal
      open={props.autopayOpen}
      onClose={() => props.onAutopayOpen(false)}
      className={`semanticModal addauto-account-modal ${validOptions.length === undefined ? 'loading' : (validOptions.length) < 1 && 'no-leases'}`}
      trigger={
        <Button
          className="btn btn-primary"
          onClick={() => triggerMethod()}
          color="black"
        >
          Add Autopay
        </Button>
      }
    >
      {
        validOptions &&
        validOptions.length > 0 ?
        <form onSubmit={props.scheduleItem}>
          <Modal.Header style={{paddingTop:"15px", paddingBottom:'15px'}}>
            Set Autopay 
            {concessionsPreMonth > val.amount && useConcessions &&<> <br></br><span style={{color:"red", fontSize:'8px'}}>Due to Concessions your account will only be charged autopay minimum of $1</span></>}
            <img
              src="/assets/img/close-modal.svg"
              alt="close"
              onClick={() => props.onAutopayOpen(false)}
            ></img>
            <br></br>
            <p style={{fontSize:'10px', textAlign:'center', color:'red'}}>Autopay setup after the end of the month will not activate until the following invoice.</p>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div className="form-group">
                <label>Lease</label>
                <br></br>
                <select
                  onChange={() => runCheck()}
                  style={{ border: "none", width: "100%" }}
                  id="lease"
                  name="lease"
                >
                  {validOptions &&
                    validOptions.map((lease) => {
                      return (
                        <option value={JSON.stringify(lease.node)}>
                          {lease.node.location.name} - #
                          {lease.node.unit && lease.node.unit.number}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                <label>Payment Method</label>
                <br></br>
                <select
                  style={{ border: "none", width: "100%" }}
                  id="method"
                  name="method"
                  onChange={(e) => setMethod(e.target.value)}
                >
                  {Options.map((option) => {
                    return (
                      <option value={JSON.stringify(option)}>
                        {option.paymentMethod}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-rw">
                <div className="form-group">
                  <label>Start Month</label>
                  <Datetime
                    isValidDate={disableCurrentMt}
                    onChange={(e) => setStart(e)}
                    timeFormat={false}
                    id="startDate"
                    dateFormat="MM/Y"
                    name="startDate"
                  />
                  <span
                    style={{ display: "none" }}
                    id="startDate"
                    name="startDate"
                  >
                    {moment(start).format("L")}
                  </span>
                </div>
                <div className="form-group">
                  <label>End Date</label>
                  <input
                    readonly="readonly"
                    id="endDate"
                    name="endDate"
                    className="form-control"
                    value={moment(val.endDate).format("L")}
                  ></input>
                  {/* <span style={{display:"none"}} id="endDate" name="endDate">{moment(end).format('L')}</span> */}
                </div>
              </div>
              <div className="form-group">
                <label>Lease Amount</label>
                <input
                  readonly="readonly"
                  id="amount"
                  name="amount"
                  className="form-control"
                  value={val.amount}
                ></input>
              </div>

              <div className="form-group">
                <label>Process on</label>
                <br></br>
                <select
                  style={{ border: "none", width: "100%" }}
                  id="process"
                  name="process"
                >
                  <option value="01">1st</option>
                  <option value="02">2nd</option>
                  <option value="03">3rd</option>
                  <option value="04">4th</option>
                  <option value="05">5th</option>
                </select>
              </div>
            </Modal.Description>
          </Modal.Content>
          <Segment.Group horizontal>
            <Segment textAlign="center">CC Fee({ccInfo.chargeType== "fixed"? `$${ccInfo.amount}`:`${ccInfo.amount}%`}) - ${parseFloat(ccInfo.convenienceAmount).toFixed(2)}</Segment>
            <Segment textAlign="center">ACH Fee({achInfo.chargeType== "fixed"? `$${achInfo.amount}`:`${achInfo.amount}%`}) - ${parseFloat(achInfo.convenienceAmount).toFixed(2)}</Segment>
          </Segment.Group>
          {
            concessionsAmount&&
            <>
            <Segment.Group horizontal style={{marginBottom:'0px'}}>
             
            <Segment textAlign="center" >Use concessions? 
            <Checkbox id="conc" name="conc" value={useConcessions} onChange={() => toggleConcessions(useConcessions==true?false:true)}></Checkbox> 
            </Segment>
            <Segment textAlign="center">${concessionsAmount.toLocaleString()}</Segment>
          </Segment.Group>
          {
            useConcessions &&
            <Segment  textAlign="center" style={{fontSize:'11px', marginTop:'0px', marginBottom:'0px'}}>{`(Concessions):$${parseFloat(concessionsAmount).toFixed(2)} / (Months):${monthLength&&monthLength.toFixed(0)} = (Amount saved monthly):$${monthLength&&monthLength.toFixed(0) >= 1 ?Math.floor(concessionsPreMonth *100)/100:0}`}</Segment>
          }
          {
            concessionsPreMonth > val.amount && useConcessions &&
            <Segment  textAlign="center" style={{fontSize:'11px', marginTop:'0px',marginBottom:'0px'}}>{`(Lease):$${parseFloat(val.amount).toFixed(2)} - (Max Concessions):$${val.amount - 1} = (Autopay):$1`}</Segment>
          }
          </>
          }
          <Modal.Actions style={{paddingTop:'10px'}}>
            {
              monthLength &&monthLength.toFixed(0) >=1 ?
            <Button disabled={!start || !end} color="black" type="submit">
            {props.process ?
                <LeaLoader wrapperStyle={{position: 'relative', left: '-10px', bottom: '16px'}} logoStyle={{height: '60px', width: '60px'}}/>
              :
              <>
              {
                (concessionsPreMonth > val.amount) &&useConcessions?
                `Add Autopay at $1`:
                `Add Autopay $${chosenOptions.paymentMethodType == "ACH"?
                useConcessions?  Math.floor((parseFloat(achInfo.totalAmount).toFixed(2) - Math.floor(concessionsPreMonth*100)/100)*100)/100 : parseFloat(achInfo.totalAmount).toFixed(2)
                :
                useConcessions?  Math.floor((parseFloat(ccInfo.totalAmount).toFixed(2) - Math.floor(concessionsPreMonth*100)/100) *100)/100: parseFloat(ccInfo.totalAmount).toFixed(2)
                }`
              }
              </>
            }
            </Button>:
            <Button disabled color="black" type="submit">
              Unable to Add Autopay due to Lease Date
            </Button>

            }
          </Modal.Actions>
        </form>
        :
          validOptions ?
            <>
              <Modal.Header>
                No Eligible Leases
                <img
                  src="/assets/img/close-modal.svg"
                  alt="close"
                  onClick={() => props.onAutopayOpen(false)}
                ></img>
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <Grid centered>
                    <Grid.Row>
                      <Grid.Column>
                        It appears you do not have any leases that extend beyond this month.
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="black" onClick={() => props.history.push("/messages")}>
                  Contact a Property Manager
                </Button>
              </Modal.Actions>
            </>
          :
            <LeaLoader wrapperStyle={{position: 'static', margin: '0 auto', width: '200px'}} logoStyle={{position: 'static', margin: '0 auto', width: '200px'}} text/>
      }
    </Modal>
  );
}

export default AutopaySetup;
