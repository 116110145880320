import React, { useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import { MONTH_NUMS, YEAR_NUMS } from "../../utils/constants";
import LeaLoader  from "../Loader/LeaLoader";

export function CreditCardModal(props) {
  const [number, setNumberValid] = React.useState("");
  const [name, setNameValid] = React.useState("");
  const [zip, setZipValid] = React.useState("");
  const [cvv, setCvvValid] = React.useState("");
  const [logo, setLogo] = React.useState("");
  /* eslint-disable */
  const checkValid = () => {
    if (!name || (name && name.trim() === "")) {
      return true;
    }
    if (
      !number ||
      (number && number.trim() === "") ||
      (number && number.trim().length < 13)
    ) {
      return true;
    }
    if (!cvv || (cvv && cvv.trim() === "")) {
      return true;
    }
    if (!zip || (zip && zip.trim() === "") || (zip && zip.trim().length < 5)) {
      return true;
    } else return false;
  };
  /* eslint-enable */

  useEffect(() => {
    GetCardType();
  });

  const GetCardType = () => {
    var re = new RegExp("^4");
    if (number.match(re) != null) {
      setLogo("logo-visa.svg");
    }
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    ) {
      setLogo("master-logo.svg");
    }
    re = new RegExp("^3[47]");
    if (number.match(re) != null) {
      setLogo("logo-amex.svg");
    }
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) {
      setLogo("logo-discover.svg");
    }
    re = new RegExp("^36");
    if (number.match(re) != null) {
      setLogo("logo-diners.svg");
    }
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) {
      setLogo("logo-jcb.svg");
    }
    return "";
  };

  return (
    <Modal
      open={props.showModal}
      onClose={() => props.toggleIsShowing(false)}
      size="large"
      className="semanticModal addcredit-card-modal"
    >
      <form onSubmit={props.handleAddCreditCardSubmit}>
        <Modal.Header>
          Add Credit Card
          <img
            src="/assets/img/close-modal.svg"
            id="close"
            alt="close"
            onClick={() => props.toggleIsShowing(false)}
          ></img>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="card-holder">
              <img src={logo && `/assets/img/${logo}`} alt=""></img>
              <div className="form-rw">
                <input
                  type="text"
                  value={
                    number.length === 15
                      ? number.replace(/\b(\d{4})(\d{6})(\d{5})\b/, "$1-$2-$3")
                      : number.toString().replace(/\d{4}(?=.)/g, "$&-")
                  }
                  onChange={(event) => {
                    setNumberValid(event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  name="number"
                  id="number"
                  className="form-control"
                ></input>
              </div>
              <div className="footer-data">
                <div className="left-data">
                  <label>Card Holder Name</label>
                  <input
                    type="text"
                    onChange={(event) => {
                      setNameValid(event.target.value);
                    }}
                    name="name"
                    id="name"
                    className="form-control name-field"
                  ></input>
                </div>
                <div className="right-data">
                  <label>Month</label>
                  <select
                    style={{ border: "none" }}
                    name="month"
                    id="month"
                    className="form-control-select"
                  >
                    {Object.keys(MONTH_NUMS).map((key, val) => {
                      return (
                        <option value={MONTH_NUMS[val]}>
                          {MONTH_NUMS[val]}
                        </option>
                      );
                    })}
                  </select>
                  {/* <input type="number" onChange={(event) => {setMonthValid(event.target.value)}} className="form-control"></input> */}
                </div>
                <div className="right-data">
                  <label>Year</label>
                  <select
                    style={{ border: "none" }}
                    name="year"
                    id="year"
                    className="form-control-select"
                  >
                    {Object.keys(YEAR_NUMS).map((key, val) => {
                      return (
                        <option value={YEAR_NUMS[val]}>{YEAR_NUMS[val]}</option>
                      );
                    })}
                  </select>
                  {/* <input type="number" max="4"  onChange={(event) => {setYearValid(event.target.value)}} name="year" id="year" className="form-control"></input> */}
                </div>
              </div>
            </div>
            {/* <div className="form-group">
                            <label>Card Number</label>
                            <input type="text" className="form-control"></input>
                        </div>
                        <div className="form-group">
                            <label>Card Holder Name</label>
                            <input type="number" className="form-control"></input>
                        </div> */}
            <div className="form-rw">
              <div className="form-group">
                <label>Billing Zip</label>
                <input
                  type="tel"
                  value={zip}
                  maxlength="5"
                  onChange={(event) => {
                    setZipValid(event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  name="zip"
                  id="zip"
                  className="form-control"
                ></input>
              </div>
              <div className="form-group">
                <label>CVV</label>
                <input
                  type="tel"
                  value={cvv}
                  onChange={(event) => {
                    setCvvValid(event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  name="cvv"
                  id="cvv"
                  className="form-control"
                ></input>
              </div>
            </div>
            {/* <div className="form-group">
                            <label>ZIP Code for billing address</label>
                            <input type="text" className="form-control"></input>
                        </div> */}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            disabled={checkValid() || props.process}
            type="submit"
          >
            {props.process ?
              <LeaLoader wrapperStyle={{position: 'relative', left: '-10px', bottom: '16px'}} logoStyle={{height: '60px', width: '60px'}}/>
            :
              'Add Credit Card'
            }
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
}

export default CreditCardModal;
