import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { isMobile, isDesktop } from "react-device-detect";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../../Auth/Firebase";

import { MOBILE_CHAT_ROOM_VIEW, MOBILE_CHAT_VIEW } from "../../Messages";
import SendMessage from "../SendMessage";
import ArchiveRequestModal from "../ArchiveRequestModal";
import RoomsListFilterTabs from "../RoomsListFilterTabs";
import ChatHeader from "./ChatHeader";
import NoConversation from "./NoConversation";
import ChatRoomListItem from "./ChatRoomListItem";
import ChatLogs from "./ChatLogs";

import { getClient } from "../../../../store/auth";
import { CHAT_URL, decodeBase64ID, encodeBase64ID } from "../../../../utils";
import {
  getChatRoomLogsQuery,
  getChatRoomsQuery,
  sendInAppMessageGql,
  sendTextMessageGql,
} from "../../../../store/chat/chat";
import { uploadAttachedFiles } from "../../../../utils/chat";
import { useDispatch } from "react-redux";
import {
  SET_CHAT_LOG,
  SET_CHAT_ROOM_LIST,
  SET_LAST_MESSAGE,
} from "../../../../store/redux/chat";

const chatApi = getClient(CHAT_URL);

const ListOfRooms = ({ archived, mode }) => {
  const [filterList, setFilterList] = useState("All"); // Filters defined in RoomsListFilterTabs
  const [activeChat, setActiveChat] = useState(null);
  const [messageType, onMessageTypeChange] = useState("in-app");
  const [messageText, onChangeText] = useState("");
  const [media, setMedia] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiveModalRoomId, setArchiveModalRoomId] = useState(0);

  const attachmentRef = React.useRef();
  const chatLogsRef = React.useRef(null);
  const dispatch = useDispatch();

  const { chatRoomId } = useParams();

  const CHAT_ROOM_VIEW_LOGIC =
    (mode === MOBILE_CHAT_ROOM_VIEW && isMobile) || isDesktop;
  const CHAT_VIEW_LOGIC = (mode === MOBILE_CHAT_VIEW && isMobile) || isDesktop;

  const { chat, person } = useSelector((store) => store);

  let roomList = chat.roomList;
  if (archived) roomList = roomList.filter(({ isArchived }) => isArchived);

  const addMedia = (files) => {
    setMedia([...media, ...files]);
  };

  const getChatRooms = async () => {
    try {
      const chatRoomsDataQuery = await chatApi.query({
        query: getChatRoomsQuery,
      });

      const roomsList = chatRoomsDataQuery.data.getChatRooms.edges.map(
        ({ node }) => {
          const {
            id,
            createdAt,
            creatorId,
            isGroup,
            listMemberId,
            locationId,
            memberId,
            name,
            updatedAt,
            preview,
          } = node;

          const chatRoomId = decodeBase64ID(id);
          let previewMessage = JSON.parse(preview);

          previewMessage = previewMessage.replaceAll('"', "");
          if (previewMessage.length > 25)
            previewMessage = `${previewMessage.substring(0, 25)}...`;

          return {
            id,
            chatRoomId,
            roomTitle: name,
            isGroup,
            creatorId,
            isArchived: false,
            createdAt,
            updatedAt,
            listMemberId,
            locationId,
            memberId,
            previewMessage,
            label: "",
            avatar: "",
            badge: 3,
          };
        }
      );

      dispatch({ type: SET_CHAT_ROOM_LIST, payload: roomsList });
    } catch (error) {
      console.log("ERROR[getChatRooms]:", error);
    } finally {
    }
  };

  const getChatLogs = async (chatRoomId) => {
    try {
      const chatLogsQuery = await chatApi.query({
        query: getChatRoomLogsQuery,
        variables: { chatRoomId },
      });

      const logs = chatLogsQuery.data.getChatRoomLogsRenter.edges.map(
        ({ node }) => {
          const { body, chatRoomId, media, timeStamp, senderId, messageLabel } =
            node;
          return {
            // id,
            senderId,
            chatRoomId,
            body,
            messageLabel,
            timeStamp,
            media: typeof media !== "string" ? [] : JSON.parse(media),
          };
        }
      );
      if (logs.length > 0)
        dispatch({
          type: SET_LAST_MESSAGE,
          payload: {
            chatRoomId,
            preview: logs[logs.length - 1].body,
          },
        });

      dispatch({
        type: SET_CHAT_LOG,
        payload: {
          [chatRoomId]: logs,
        },
      });
    } catch (error) {
      console.log("error [chatLogs]", error);
    }
  };

  const sendInAppMessageToApi = async (media = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          chatRoomId: activeChat.chatRoomId,
          senderId: person.ndbId,
          body: messageText,
          media,
        };

        const response = await chatApi.mutate({
          mutation: sendInAppMessageGql,
          variables: { package: params },
        });

        if (response?.data?.sendInAppMessage?.response === "HTTPStatus.OK") {
          resolve(new Date());
        }
      } catch (error) {
        reject();
        console.log("[CHAT SEND MESSAGE]", error);
      }
    });
  };

  const sendTextMessageToApi = async (media = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const receiverId =
          activeChat?.memberId === person.ndbId
            ? activeChat?.creatorId
            : activeChat?.memberId;
        const params = {
          chatRoomId: activeChat.chatRoomId,
          senderId: person.ndbId,
          receiverId,
          body: messageText,
          media,
        };

        const response = await chatApi.mutate({
          mutation: sendTextMessageGql,
          variables: { package: params },
        });

        if (response?.data?.sendInAppMessage?.response === "HTTPStatus.OK") {
          resolve(new Date());
        }
      } catch (error) {
        reject();
        console.log("[CHAT SEND MESSAGE]", error);
      }
    });
  };

  const sendMessage = async () => {
    try {
      let upload = null;
      if (media.length > 0) {
        upload = await uploadAttachedFiles({
          locationId: encodeBase64ID("Location", activeChat.locationId),
          chatRoomId: activeChat.chatRoomId,
          files: media,
        });
      }

      let attachedMedia = [];
      if (upload?.data?.uploadMedia?.response === "200") {
        attachedMedia = upload?.data?.uploadMedia?.package.map(
          ({ name, path, url, type, chat_room_id }) => {
            return {
              file_name: name,
              uri: url,
              content_type: type,
            };
          }
        );
      }

      if (messageType === "in-app") {
        await sendInAppMessageToApi(attachedMedia);
      } else if (messageType === "sms") {
        await sendTextMessageToApi(attachedMedia);
      }

      // console.log({
      //   type: messageType,
      //   body: messageText,
      //   chatRoomId: activeChat.id,
      //   senderId: person.ndbId,
      //   media,
      // });
      onChangeText("");
    } catch (error) {
      console.log("Error", error);
    }
  };

  useMemo(() => {
    if (messaging !== null) {
      onMessage(messaging, (payload) => {
        getChatLogs(payload.data.chat_room_id);
      });
    }
    getChatRooms();
  }, []);

  // get chat room data when chat room changed
  useEffect(() => {
    const room = roomList.filter(
      (room) => room?.chatRoomId.toString() === chatRoomId
    );
    if (room.length > 0) setActiveChat(room[0]);
  }, [chatRoomId, roomList]);

  // no conversation
  if (roomList.length === 0) return <NoConversation />;

  return (
    <>
      {CHAT_ROOM_VIEW_LOGIC && (
        <RoomsListFilterTabs
          {...{ onClick: setFilterList, activeFilter: filterList, roomList }}
        />
      )}

      <div className="chat-container">
        {CHAT_ROOM_VIEW_LOGIC && (
          <div className={`room-list-container`}>
            <div className="border-wrapper">
              {archived && (
                <div className="to-messages-nav">
                  <div className="archive-title">ARCHIVED CONVERSATIONS</div>
                  <Link to="/messages">Back to messages</Link>
                </div>
              )}
              {roomList.map((item, key) => {
                // TODO: Receiver data not found!
                // if (item.receiverContactData === null) return null;
                // if (item.receiverContactData.label !== filterList && filterList !== ALL)
                //   return null;
                return (
                  <ChatRoomListItem
                    {...{
                      key,
                      item,
                      labelFilter: filterList,
                      isActive: activeChat?.chatRoomId === item.chatRoomId,
                    }}
                  />
                );
              })}
            </div>
            {/* {!archived && (
              <Link to="/messages/archived" className="archived-button">
                VIEW ARCHIVED
              </Link>
            )} */}
          </div>
        )}

        {CHAT_VIEW_LOGIC && (
          <div className={`chat-window`}>
            {activeChat !== null && (
              <>
                <ChatHeader
                  {...{
                    ...activeChat,
                    setVisibleModal: setShowArchiveModal,
                    setModalData: setArchiveModalRoomId,
                  }}
                  description={""}
                />
                <ChatLogs {...{ ref: chatLogsRef }} />
                <SendMessage
                  {...{
                    onChangeText,
                    onMessageTypeChange,
                    activeMessageType: messageType,
                    sendMessage,
                    value: messageText,
                    attachmentRef,
                    addMedia,
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>

      <ArchiveRequestModal
        {...{
          id: archiveModalRoomId,
          visible: showArchiveModal,
          setVisible: setShowArchiveModal,
        }}
      />
    </>
  );
};

export default ListOfRooms;
