import React, { Component } from "react";
import { Step } from "semantic-ui-react";
import moment from "moment";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { Prompt } from "react-router-dom";
import isEqual from "lodash/isEqual";
import ApplicationUnitInfo from "./unitInfo";
import PrimaryApplicant from "./PrimaryApplicant";
import CoApplicants from "./CoApplicants";
import Review from "./Review";
import Occupants from "./Occupants";
import { parseGraphQLErrors } from "../../utils/common";
import {
  DATE_FORMAT,
  NUMBER_ZERO,
  UNITAPI,
  LOCATION_UTILS,
} from "../../utils/constants";
import { unitInfo, fetchBackgroundInfo } from "../../store/person/properties";
import "./Applications.scss";
import { createApplication } from "../../store/person/applications";
import toaster from "../../store/utils/toaster";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
const coapplicantData = {
  firstName: "",
  lastName: "",
  email: "",
  isFinanciallyResponsible: false,
};
const petsData = {
  name: " ",
  petType: "",
  weight: 0,
  color: "",
  breed: "",
  gender: "",
  age: 0,
  service: false,
};
const occupantsData = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
};

const unitManager = getClient(UNITAPI);
const backgroundClient = getClient(LOCATION_UTILS);
class ApplicationForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const data = (props.location && props.location.Unit) || [];
    const locationData =
      props.match && props.match.params
        ? props.match.params
        : props.contactLocation;
    const user = props.user || {};
    !locationData &&
      !locationData.propertyId &&
      props.history &&
      props.history.goBack();
    this.state = {
      backgroundInfo: [],
      locationId: locationData.propertyId
        ? locationData.propertyId
        : props.location,
      loading: false,
      date: "",
      sending: false,
      ageCheckbox: false,
      termsConditions: false,
      verification: false,
      privacyPolicy: false,
      applicantCheck: false,
      affordableText: (data && data.affordableHousingProgram && data.affordableHousingProgram.name) || '',
      unitTypeText: (data && data.__typename) || '',
      bathrooms: '',
      bedrooms: '',
      level: '',
      unitNumberText: (locationData&&locationData.unitNumber) || '',
      formSteps: {
        unitInfo: true,
        primaryApplicant: false,
        coApplicant: false,
        occupants: false,
        review: false,
      },
      application: {
        locationId: locationData&&locationData.propertyId,
        targetMoveInDate: (data && data.targetMoveInDate) || '',
        duration: (data && data.duration) || 'one_year',
        unitType: (data && data.unitTypesId) || '',
        unitNumber: (locationData&&locationData.unitNumber) || '',
        affordableHousingProgram: (data && data.affordableHousingProgram && data.affordableHousingProgram.code) || '',
        children: data ? data.children : 0,
        petsNumber: data ? data.petsNumber : 0,
        adults: data ? data.adults : 1,
        comment: "",
        liquidFilledFurniture: data && data.liquidFilledFurniture,
        smoke: data && data.smoke,
        primaryApplicant: {
          firstName: user.firstName,
          lastName: user.lastName,
          phoneMobile: user.phoneMobile,
          dateOfBirth:
            (data &&
              data.primaryApplicant &&
              moment(data.primaryApplicant.dateOfBirth).format("L")) ||
            moment(user.dateOfBirth).format("L"),
          drivingLicence: {
            number:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.drivingLicence &&
                data.primaryApplicant.drivingLicence.number) ||
              "",
            idType:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.drivingLicence &&
                data.primaryApplicant.drivingLicence.idType) ||
              "DrivingLicence",
            issuingState:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.drivingLicence &&
                data.primaryApplicant.drivingLicence.issuingState) ||
              "",
          },
          maritalStatus:
            (data &&
              data.primaryApplicant &&
              data.primaryApplicant.maritalStatus) ||
            "",
          noEmploymentHistory: false,
          otherIncomes: [
            {
              source: "",
              monthlyIncome: "",
            },
          ],
          rentalHistory: [
            {
              monthlyRent: "",
              landlordName: "",
              landlordEmail: "",
              landlordPhone: "",
              isCurrentlyResiding: false,
              startDate: "",
              endDate: "",
              address: {
                streetLine1: "",
                streetLine2: "",
                city: "",
                state: "",
                postcode: "",
              },
            },
          ],
          employmentHistory: [
            {
              title: "",
              employerName: "",
              monthlyIncome: 0,
              startDate: "",
              endDate: "",
              supervisorName: "",
              supervisorEmail: "",
              supervisorPhone: "",
              isCurrentlyWorking: false,
              address: {
                streetLine1: "",
                city: "",
                state: "",
                postcode: "",
              },
            },
          ],
          emergencyContact: {
            name:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.emergencyContact.name) ||
              "",
            email:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.emergencyContact.email) ||
              "",
            relationship:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.emergencyContact.relationship) ||
              "",
            mobilePhone:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.emergencyContact.mobilePhone) ||
              "",
            workPhone:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.emergencyContact.workPhone) ||
              "",
            homePhone:
              (data &&
                data.primaryApplicant &&
                data.primaryApplicant.emergencyContact.homePhone) ||
              "",
            address: {
              streetLine1:
                (data &&
                  data.primaryApplicant &&
                  data.primaryApplicant.emergencyContact.address.streetLine1) ||
                "",
              city:
                (data &&
                  data.primaryApplicant &&
                  data.primaryApplicant &&
                  data.primaryApplicant.emergencyContact.address.city) ||
                "",
              state:
                (data &&
                  data.primaryApplicant &&
                  data.primaryApplicant.emergencyContact.address.state) ||
                "",
              postcode:
                (data &&
                  data.primaryApplicant &&
                  data.primaryApplicant.emergencyContact.address.postcode) ||
                "",
            },
          },
          backgroundChecks: {
            checks: [],
            comment: "",
          },
        },
        pets: [petsData],
        vehicles: [
          {
            make: "",
            model: "",
            color: "",
            year: "",
            licensePlate: "",
            state: "",
          },
        ],
        occupants: [occupantsData],
        coApplicants: [coapplicantData],
      },
      applicationSubmitted: false,
    };
  }

  componentDidMount() {
    if (this.props.location.data) {
      this.updateLocalState();
    }
    this.fetchBackgroundInfo();
    window.addEventListener("beforeunload", this.alertUser);
    // this.checkForTenantAlert();
    this.fetchUnitData();
  }

  fetchBackgroundInfo = async() => {
    if(this.state.locationId){

      const BackgroundInfo = await backgroundClient.query({query:fetchBackgroundInfo, variables: {locationId: this.state.locationId}})
      this.setState({backgroundInfo: BackgroundInfo.data.locationUtils && BackgroundInfo.data.locationUtils.edges.length && BackgroundInfo.data.locationUtils.edges[0].node.applicationInformation},() => this.setState({loading:false}))
    }
  }

  componentDidUpdate(prevProps) {
    const { user, location } = this.props;
    if (!isEqual(prevProps.user, user)) {
      const data = (location && location.Unit) || [];
      this.setState((prevState) => ({
        application: {
          ...prevState.application,
          primaryApplicant: {
            ...prevState.application.primaryApplicant,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneMobile: user.phoneMobile,
            dateOfBirth:
              (data &&
                data.primaryApplicant &&
                moment(data.primaryApplicant.dateOfBirth).format("L")) ||
              moment(user.dateOfBirth).format("L"),
          },
        },
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.alertUser);
  }

  alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    return "Are you sure you want to leave this page?";
  };

  // checkForTenantAlert = async () => {
  //   const { applicationManager, user } = this.props;
  //   const { application } = this.state;
  //   await applicationManager.query({
  //     query: tenantAlertCheck,
  //     variables: {
  //       email: user.email
  //     }
  //   }).then(res => {
  //     this.setState({ loading: false, application: {...application, primaryApplicant: {...application.primaryApplicant,
  //       tenantAlert: (res.data.decisionSummaryCheck.edges[0].node && res.data.decisionSummaryCheck.edges[0].node.isBackgroundExpired) || false}} });
  //   }).catch(error => {
  //     this.setState({ loading: false });
  //     toast.error(`Oops! There was a problem`)
  // })
  // }

  fetchUnitData = async () => {
    const { application } = this.state;
    const { locationId, unitNumber } = application;
    locationId &&
      (await unitManager
        .query({
          query: unitInfo,
          variables: { locationId: locationId, unitId: unitNumber },
        })
        .then((res) => {
          const unitData = res.data.unit && res.data.unit.edges[0].node;
          this.setState({
            unitTypeText: unitData.unitType.type,
            bathrooms: unitData.unitType.bathrooms,
            bedrooms: unitData.unitType.bedrooms,
            level: unitData.level,
            application: { ...application, unitType: unitData.unitType.id },
          });
        })
        .catch((error) => {
          console.log(error);
        }));
  };

  updateLocalState = () => {
    const data = this.props.location.data;
    const { application } = this.state;
    let rentalArr = data.primaryApplicant.rentalHistory;
    let employmentArr = data.primaryApplicant.employmentHistory;
    let otherIncomeArr = data.primaryApplicant.otherIncomes;
    let backgroundInfo = data.primaryApplicant.backgroundChecks;
    let coApplicantsLocalArr = data.coApplicants;
    let petsLocalArr = data.pets;
    let childrenLocalArr = data.occupants;
    let vehiclesLocalArr = data.vehicles;
    this.setState({
      application: {
        ...application,
        id: data.id,
        primaryApplicant: {
          ...application.primaryApplicant,
          rentalHistory: rentalArr,
          employmentHistory: employmentArr,
          otherIncomes: otherIncomeArr,
          backgroundChecks: backgroundInfo,
        },
        coApplicants: coApplicantsLocalArr,
        pets: petsLocalArr,
        occupants: childrenLocalArr,
        vehicles: vehiclesLocalArr,
      },
    });
  };

  setToOriginalState = (updatedArr, type) => {
    let newArr = [];
    switch (type) {
      case "coApplicants":
        newArr = updatedArr.concat(coapplicantData);
        break;
      case "occupants":
        newArr = updatedArr.concat(occupantsData);
        break;
      case "pets":
        newArr = updatedArr.concat(petsData);
        break;
      default:
        break;
    }
    return newArr;
  };

  setLocalAgeState = () => {
    this.setState({ ageCheckbox: !this.state.ageCheckbox });
  };

  /* function to set the coapplicants, occupants and pets array
    based on the values entered in the unit info page */
  setArrayNumber = (application, type, originalArr, updatedArr) => {
    if (Number(application[type]) === NUMBER_ZERO) {
      updatedArr = [];
      this.setState({ application: { ...application, [originalArr]: [] } });
    } else {
      while (
        updatedArr.length <
        (type === "adults"
          ? Number(application[type]) - 1
          : Number(application[type]))
      ) {
        updatedArr = this.setToOriginalState(updatedArr, originalArr);
      }
      while (
        (type === "adults"
          ? Number(application[type]) - 1
          : Number(application[type])) < updatedArr.length
      ) {
        updatedArr.pop();
      }
    }
    return updatedArr;
  };

  /* function to set the co-applicants, children and pets array on the basis of the number of adults
    co-applicants is one less than the number of adults */
  setCoApplicantArray = () => {
    const { application, locationId } = this.state;
    let coApplicantsArr = application.coApplicants
      ? [...application.coApplicants]
      : [];
    let childrenArr = application.occupants ? [...application.occupants] : [];
    let petsArr = application.pets ? [...application.pets] : [];
    coApplicantsArr = this.setArrayNumber(
      application,
      "adults",
      "coApplicants",
      coApplicantsArr
    );
    childrenArr = this.setArrayNumber(
      application,
      "children",
      "occupants",
      childrenArr
    );
    petsArr = this.setArrayNumber(application, "petsNumber", "pets", petsArr);
    this.setState({
      application: {
        ...application,
        locationId: locationId,
        coApplicants: coApplicantsArr,
        occupants: childrenArr,
        pets: petsArr,
      },
    });
  };

  /* function to update the active page of application by updating the formSteps local state */
  updateFormSteps = (number) => {
    const { formSteps } = this.state;
    switch (number) {
      case 0:
        this.setState({
          formSteps: {
            ...formSteps,
            unitInfo: true,
            primaryApplicant: false,
            coApplicant: false,
            occupants: false,
            review: false,
          },
        });
        break;
      case 1:
        this.setState({
          formSteps: {
            ...formSteps,
            unitInfo: false,
            primaryApplicant: true,
            coApplicant: false,
            occupants: false,
            review: false,
          },
        });
        this.setCoApplicantArray();
        break;
      case 2:
        this.setState({
          formSteps: {
            ...formSteps,
            unitInfo: false,
            primaryApplicant: false,
            coApplicant: true,
            occupants: false,
            review: false,
          },
        });
        this.setCoApplicantArray();
        break;
      case 3:
        this.setState({
          formSteps: {
            ...formSteps,
            unitInfo: false,
            primaryApplicant: false,
            coApplicant: false,
            occupants: true,
            review: false,
          },
        });
        this.setCoApplicantArray();
        break;
      case 4:
        this.setState({
          formSteps: {
            ...formSteps,
            unitInfo: false,
            primaryApplicant: false,
            coApplicant: false,
            occupants: false,
            review: true,
          },
        });
        break;
      default:
        break;
    }
  };

  /* function to update the dropdown textual values
    on the basis of field values */
  updateDropdownText = (field, name) => {
    switch (field) {
      case "affordableHousingProgram":
        this.setState({ affordableText: name });
        break;
      case "unitType":
        this.setState({ unitTypeText: name });
        break;
      case "unitNumber":
        this.setState({ unitNumberText: name });
        break;
      default:
        break;
    }
  };

  updateDropdownValues = (event, { value }) => {
    const { application } = this.state;
    const { id, field, name } = value;
    name && this.setState({ affordableText: name });
    this.updateDropdownText(field, name);
    this.setState({ application: { ...application, [field]: id } });
    if(value && value.field == "unitNumber"){
      this.setState({ application: { ...application, level: value.level } });
    }
  };

  updateApplicantName = (e, field) => {
    const { application } = this.state;
    this.setState({
      application: {
        ...application,
        primaryApplicant: { ...application.primaryApplicant, [field]: e },
      },
    });
  };
  updateApplicantUnit = (e, field) => {
    console.log(e, field);
    const { application } = this.state;
    this.setState({ application: { ...application, [field]: e } });
  };

  updateApplicationState = (e, field, name) => {
    const { application } = this.state;
    this.setState({
      application: {
        ...application,
        [name]: {
          ...application[name],
          [field]: e.target.textContent || e.target.value,
        },
      },
    });
  };

  updateUnitInfoState = (e, field, data) => {
    const { application } = this.state;
    let value = e.target.value;
    switch (field) {
      case "adults":
      case "children":
      case "petsNumber":
        value = !isNaN(Number(e.target.value))
          ? Number(e.target.value)
          : e.target.value;
        break;
      case "liquidFilledFurniture":
      case "smoke":
        if (e.target.textContent === "Yes") value = true;
        else if (e.target.textContent === "No") value = false;
        break;
      default:
        value = e.target.value;
        break;
    }
    this.setState({ application: { ...application, [field]: value } });
  };

  updateDuration = (e, data, field) => {
    const { application } = this.state;
    this.setState({ application: { ...application, [field]: data.value } });
  };

  updateNestedState = (e, field, nestedKey, parentKey) => {
    const { application } = this.state;
    let value;
    if (field === "idType") {
      value = e;
    } else if (field === "comment") {
      value = e.target.value;
    } else {
      value = e.target.textContent || e.target.value;
    }
    this.setState({
      application: {
        ...application,
        [parentKey]: {
          ...application[parentKey],
          [nestedKey]: { ...application[parentKey][nestedKey], [field]: value },
        },
      },
    });
  };

  handleAddressChange = (field, value) => {
    const { application } = this.state;
    this.setState({
      application: {
        ...application,
        primaryApplicant: {
          ...application.primaryApplicant,
          address: {
            ...application.primaryApplicant.address,
            [field]: {
              ...application.primaryApplicant.address[field],
              streetLine1: value,
            },
          },
        },
      },
    });
    if (field === "addressOne") {
      this.setState({ address: value, addressV: value });
    }
  };

  /* function to set the co-applicants direct parameters */
  setCoApplicantDirectFields = (
    field,
    value,
    index,
    type,
    parentKey,
    nestedKey
  ) => {
    const { application } = this.state;
    const newArr = [...application[type]];
    if (nestedKey) {
      newArr[index] = {
        ...newArr[index],
        [parentKey]: {
          ...newArr[index][parentKey],
          [nestedKey]: {
            ...newArr[index][parentKey][nestedKey],
            [field]: value,
          },
        },
      };
    } else if (parentKey && !nestedKey) {
      newArr[index] = {
        ...newArr[index],
        [parentKey]: {
          ...newArr[index][parentKey],
          [field]: value,
        },
      };
    } else {
      newArr[index] = { ...newArr[index], [field]: value };
    }
    this.setState({ application: { ...application, [type]: newArr } });
  };

  /* function to set the address parameters */
  setRenterHistory = (field, value, index, parentKey, nestedKey) => {
    const { application } = this.state;
    const newArr = [...this.state.application.primaryApplicant[parentKey]];
    if (nestedKey) {
      newArr[index] = {
        ...newArr[index],
        [nestedKey]: { ...newArr[index][nestedKey], [field]: value },
      };
    } else {
      newArr[index] = { ...newArr[index], [field]: value };
    }
    this.setState({
      application: {
        ...application,
        primaryApplicant: {
          ...application.primaryApplicant,
          [parentKey]: newArr,
        },
      },
    });
  };

  /* function to set the address field parameters on the basis of the data type received
    calls specified to perform this action */
  setAddressFields = (data, index, parentKey, functionToCall, type) => {
    type
      ? functionToCall("city", "", index, parentKey, type, "address")
      : functionToCall("city", "", index, parentKey, "address");
    type
      ? functionToCall("state", "", index, parentKey, type, "address")
      : functionToCall("state", "", index, parentKey, "address");
    type
      ? functionToCall("postcode", "", index, parentKey, type, "address")
      : functionToCall("postcode", "", index, parentKey, "address");
    data.map((ele) => {
      const longName = ele.long_name;
      switch (ele.types[0]) {
        case "locality":
          if (type) {
            functionToCall("city", longName, index, parentKey, type, "address");
          } else {
            functionToCall("city", longName, index, parentKey, "address");
          }
          break;
        case "administrative_area_level_1":
          type
            ? functionToCall(
                "state",
                longName,
                index,
                parentKey,
                type,
                "address"
              )
            : functionToCall("state", longName, index, parentKey, "address");
          break;
        case "postal_code":
          type
            ? functionToCall(
                "postcode",
                longName,
                index,
                parentKey,
                type,
                "address"
              )
            : functionToCall("postcode", longName, index, parentKey, "address");
          break;
        default:
          break;
      }
      return null;
    });
  };

  handleNestedAddressChange = (field, value, index, parentKey, nestedKey) => {
    const { application } = this.state;
    this.setState({
      application: {
        ...application,
        primaryApplicant: {
          ...application.primaryApplicant,
          [parentKey]: {
            ...application.primaryApplicant[parentKey],
            [nestedKey]: {
              ...application.primaryApplicant[parentKey].address,
              [field]: value,
            },
          },
        },
      },
    });
  };

  /* function to accepts the date selected and set it in local state after formatting change */
  handleDateChange = (event, field, parentKey, nestedKey) => {
    const { application } = this.state;
    if (event !== null) {
      const dateRes = moment(event).format(DATE_FORMAT);
      if (nestedKey) {
        this.setState({
          application: {
            ...application,
            [parentKey]: {
              ...application.parentKey,
              [nestedKey]: {
                ...application.parentKey.nestedKey,
                [field]: dateRes,
              },
            },
          },
          date: event._d,
        });
      } else if (!nestedKey && parentKey) {
        this.setState({
          application: {
            ...application,
            [parentKey]: { ...application[parentKey], [field]: dateRes },
          },
          date: event._d,
        });
      } else {
        this.setState({
          application: { ...application, [field]: dateRes },
          date: event._d,
        });
      }
    } else {
      this.setState({
        application: { ...application, targetMoveInDate: "" },
        date: "",
      });
    }
  };

  setSelectedBackground = (id) => {
    const { application } = this.state;
    const arr = [...application.primaryApplicant.backgroundChecks.checks];
    const isPresent = arr.includes(id);
    let updatedArr = [];
    if (!isPresent) {
      updatedArr = arr.concat(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
      updatedArr = arr;
    }
    this.setState({
      application: {
        ...application,
        primaryApplicant: {
          ...application.primaryApplicant,
          backgroundChecks: {
            ...application.primaryApplicant.backgroundChecks,
            checks: updatedArr,
          },
        },
      },
    });
  };

  setTermsCheck = (e, field) => {
    this.setState({ [field]: !this.state[field] });
  };

  setPetNumber = (number) => {
    const { application } = this.state;
    this.setState({ application: { ...application, petsNumber: number } });
  };

  /* function to update tenant alert of primary applicant */
  // updateTenantAlert = (e) => {
  //   const { application } = this.state;
  //   this.setState({ application: {...application, primaryApplicant: {...application.primaryApplicant,
  //     tenantAlert: e}}});
  // }

  redirectToForm = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  removeFields = (application) => {
    application.primaryApplicant.otherIncomes =
      application.primaryApplicant.otherIncomes.filter(
        (ele) => ele.monthlyIncome
      );
    application.primaryApplicant.rentalHistory.forEach((ele) => {
      if (ele.monthlyRent === "" || ele.monthlyRent === null) {
        delete ele.monthlyRent;
      }
      if (ele.isCurrentlyResiding) {
        delete ele.endDate;
      }
    });
    application.primaryApplicant.employmentHistory.forEach((ele) => {
      if (ele.isCurrentlyWorking) {
        delete ele.endDate;
      }
    });
    if (application.primaryApplicant.noEmploymentHistory) {
      application.primaryApplicant.employmentHistory = [];
    }
  };

  createApplication = async () => {
    const { applicationManager } = this.props;
    const { application } = this.state;
    this.setState({ sending: true });
    this.removeFields(application);
    const app = { application };
    if (this.props.addRental) {
      mixpanel.track("Renter Explore Action", {
        sub: "Attach Leasera Property Submit",
      });
      this.props.contactLocation(app);
    } else {
      mixpanel.track("Renter Application Action", {
        sub: "Application Submit",
      });
      await applicationManager
        .mutate({
          mutation: createApplication,
          variables: { input: app },
        })
        .then((res) => {
          toast.success("Application created successfully");
          this.setState({ sending: false });
          this.setState({ applicationSubmitted: true });
          this.redirectToForm();
        })
        .catch((error) => {
          this.setState({ sending: false });
          toast.error(parseGraphQLErrors(error));
        });
    }
  };

  render() {
    const {
      formSteps,
      application,
      locationId,
      affordableText,
      ageCheckbox,
      termsConditions,
      sending,
      privacyPolicy,
      applicantCheck,
      verification,
      unitTypeText,
      unitNumberText,
      bathrooms,
      bedrooms,
      level,
    } = this.state;
    const { location } = this.props;
    // const Bread = () => (
    //   <Breadcrumb className="custom-breadcrumb">
    //     <Breadcrumb.Section link>
    //      <NavLink exact to="/applications">
    //           Applications
    //     </NavLink>
    //     </Breadcrumb.Section>
    //     <Breadcrumb.Divider />
    //     <Breadcrumb.Section link>
    //         {location.data ?  <NavLink exact to={`/viewApplication/location=${location.location}/id=${location.data.id}`}>
    //            {location.data.primaryApplicant.lastName}
    //      </NavLink> : location.leadData ? <NavLink exact to={`/viewLead/location=${location.leadData.location}/id=${location.leadData.id}`}>
    //            {location.leadData.lastName}
    //      </NavLink> : '' }
    //     </Breadcrumb.Section>
    //     </Breadcrumb>
    // )

    return (
      <>
        <>
          {/* <Bread />        */}
          <Prompt
            when={
              !!this.props.location.location && !this.state.applicationSubmitted
            }
            message="Are you sure you want to leave this page?"
          />
          <div className="add-new-app-head d-flex align-items-center justify-content-between">
            <h4 className="page-head mb-0">
              {this.props.location.data &&
                !this.props.addRental &&
                "Edit New Application"}
            </h4>
            <h4 className="page-head mb-0">
              {!this.props.location.data &&
                !this.props.addRental &&
                "Add New Application"}
            </h4>
            {/* <h4 className="page-head mb-0">{this.props.addRental && 'Please fill out this Lease Information'}</h4> */}
          </div>
          <div className="application-step-wrapper">
            <Step.Group className="w-100">
              <Step active={formSteps.unitInfo} description="Unit Info" />
              {!this.props.addRental && (
                <Step
                  active={formSteps.primaryApplicant}
                  description="Primary Applicant"
                />
              )}
              <Step active={formSteps.coApplicant} description="Co-Applicant" />
              <Step active={formSteps.occupants} description="Occupant" />
              <Step active={formSteps.review} description="Review" />
            </Step.Group>
          </div>

          {formSteps.unitInfo && (
            <ApplicationUnitInfo
              addRental={this.props.addRental}
              updateFormSteps={this.updateFormSteps}
              affordableHousing={this.props.affordableHousingClient}
              application={application}
              updateDuration={this.updateDuration}
              updateApplication={this.updateUnitInfoState}
              updateApplicantName={this.updateApplicantName}
              handleDateChange={this.handleDateChange}
              updateApplicantUnit={this.updateApplicantUnit}
              customId={locationId}
              propertyManagers={location.managers}
              updateDropdownValues={this.updateDropdownValues}
              affordableText={affordableText}
              selectedPrimary={location.properties}
              showComment={!location.data}
              // updateTenantAlert={this.updateTenantAlert}
              unitTypeText={unitTypeText}
              bathrooms={bathrooms}
              bedrooms={bedrooms}
              level={level}
              unitNumberText={unitNumberText}
            />
          )}
          {formSteps.primaryApplicant && !this.props.addRental && (
            <PrimaryApplicant
              addRental={this.props.addRental}
              updateFormSteps={this.updateFormSteps}
              numberOfAdults={application.adults}
              application={application.primaryApplicant}
              updateApplication={this.updateApplicationState}
              updateNestedState={this.updateNestedState}
              setRenterHistory={this.setRenterHistory}
              handleDateChange={this.handleDateChange}
              setAddressFields={this.setAddressFields}
              updateApplicantName={this.updateApplicantName}
              updateApplicantUnit={this.updateApplicantUnit}
              updateAddressChange={this.handleAddressChange}
              handleNestedAddressChange={this.handleNestedAddressChange}
              backgroundInfo={this.state.backgroundInfo}
              setBackgroundSelected={this.setSelectedBackground}
            />
          )}
          {formSteps.coApplicant && (
            <CoApplicants
              addRental={this.props.addRental}
              updateFormSteps={this.updateFormSteps}
              application={application.coApplicants}
              setCoApplicantDirectFields={this.setCoApplicantDirectFields}
              setLocalAgeState={this.setLocalAgeState}
              ageCheckbox={ageCheckbox}
            />
          )}
          {formSteps.occupants && (
            <Occupants
              addRental={this.props.addRental}
              updateFormSteps={this.updateFormSteps}
              application={application}
              setCoApplicantDirectFields={this.setCoApplicantDirectFields}
              numberOfAdults={application.adults}
              setPetNumber={this.setPetNumber}
            />
          )}
          {formSteps.review && (
            <Review
              addRental={this.props.addRental}
              updateFormSteps={this.updateFormSteps}
              application={application}
              locationId={locationId}
              unitNumberText={unitNumberText}
              unitTypeText={unitTypeText}
              bathrooms={bathrooms}
              bedrooms={bedrooms}
              level={level}
              affordableText={affordableText}
              numberOfAdults={application.adults}
              setTermsCheck={this.setTermsCheck}
              createApplication={
                location.data ? this.updateApplication : this.createApplication
              }
              termsConditions={termsConditions}
              privacyPolicy={privacyPolicy}
              applicantCheck={applicantCheck}
              verification={verification}
              backgroundInfo={this.state.backgroundInfo}
              sending={sending}
            />
          )}
          {toaster()}
        </>
      </>
    );
  }
}

ApplicationForm.defaltProps = {
  user: {},
};

export default ApplicationForm;
