import React from "react";
import PropertyDetail from "./PropertyDetail";
import { COMING_SOON } from "../../utils/constants";

const PropertyCard = ({
  ownedProp,
  withDateSaved,
  withArrow,
  withPoints,
  withStay,
  setModalProperty,
}) => {
  return (
    <>
      {ownedProp && (
        <div className="card property-card">
          <div className="card-image-box">
            <img
              className="card-img-top img-fluid"
              src={ownedProp.place.photos[0].url}
              data-holder-rendered="true"
              alt="Card img"
            />
            <div
              className="heart-btn"
              data-toggle="tooltip"
              data-placement="right"
              title={COMING_SOON}
            ></div>
            {!withArrow && <div className="active-btn">Active</div>}
          </div>
          <div className="card-body">
            {ownedProp && (
              <PropertyDetail
                property={ownedProp}
                withDateSaved={withDateSaved}
                withPoints={withPoints}
                withStay={withStay}
              />
            )}
            {withArrow && (
              <a
                data-dismiss="modal"
                data-toggle="modal"
                data-target={"#propertyDetailModal"}
                onClick={() => {
                  setModalProperty(ownedProp);
                }}
              >
                {" "}
                {/*eslint-disable-line*/}
                <div className="action-arrow"></div>
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyCard;
