import React, { Component } from "react";
import moment from "moment";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import "./Schedule.scss";
import { eventsGql } from "../../store/person/person";
import { TOURS, PERSON_URL } from "../../utils/constants";
import { updateTour } from "../../store/person/properties";
import { toast } from "react-toastify";
import { parseGraphQLErrors, toastFailMsg } from "../../utils/common";
import mixpanel from "mixpanel-browser";

import { Icon, Grid, Card, Segment, Container, Menu } from "semantic-ui-react";
const toursClient = getClient(TOURS);
const personClient = getClient(PERSON_URL);

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chosen: moment().startOf("day"),
      selectedMonth: moment(),
      selectedDay: moment().startOf("day"),
      selectedMonthEvents: [],
      showEvents: false,
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      tourData: "",
      modalOpen: false,
      events: "",
      loading: false,
    };

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.addEvent = this.addEvent.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.goToCurrentMonthView = this.goToCurrentMonthView.bind(this);

    this.initializeEvents();
  }

  componentDidMount() {
    this.getCal();
  }
  myFunction() {
    document.getElementById("today").click();
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getCal();
      });
    }
  }

  handleClose = () => this.setState({ modalOpen: false });
  getCal = () => {
    try {
      personClient
        .query({
          query: eventsGql,
        })
        .then((res) => {
          const setAgenda = [];
          const events =
            res.data.personCalendar && res.data.personCalendar.edges;
          events &&
            events.forEach((event) => {
              const split = event.node.startDate.split("T");
              const newT = moment(
                split[0] + " " + split[1],
                "DD/MM/YYYY HH:mm A"
              );
              const secT = moment.utc(newT._i);
              const newEvent = {
                title: event.node.eventName,
                time: secT.local().format("DD/MM/YYYY hh:mm A"),
                lodat: moment(event.node.startDate),
                date: moment(event.node.startDate),
                description: event.node.description,
                id: event.node.eventId,
                dynamic: true,
              };
              setAgenda.push(newEvent);
            });
          this.setState({
            selectedMonthEvents: setAgenda,
            loading: false,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  clickToday = () => {
    if (this.props.dashboard) {
      const data = { date: "" };
      data.date = moment().format("MM/DD/YYYY");
      this.select(data);
    }
  };
  previous() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.subtract(1, "month"),
    });
  }

  next() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.add(1, "month"),
    });
  }

  select(day) {
    this.setState({ chosen: day.date });
    if (!this.props.dashboard) {
      this.setState({
        selectedMonth: day.date,
        selectedDay: day.date.clone(),
        showEvents: true,
      });
    } else {
      const dayEventsRendered = [];
      for (var i = 0; i < this.state.selectedMonthEvents.length; i++) {
        if (this.state.selectedMonthEvents[i].date.isSame(day.date, "day")) {
          dayEventsRendered.push(this.state.selectedMonthEvents[i]);
        }
      }
      this.props.dashboardList(dayEventsRendered, day.date);
    }
  }

  goToCurrentMonthView() {
    this.setState({
      selectedMonth: moment(),
    });
  }

  showCalendar() {
    this.setState({
      selectedMonth: this.state.selectedMonth,
      selectedDay: this.state.selectedDay,
      showEvents: false,
    });
  }

  renderMonthLabel() {
    const currentMonthView = this.state.selectedMonth;
    return (
      <Menu.Header className="month-label">
        {currentMonthView.format("MMMM YYYY")}
      </Menu.Header>
    );
  }

  renderDayLabel() {
    const currentSelectedDay = this.state.selectedDay;
    return (
      <Menu.Header className="month-label">
        {currentSelectedDay.format("DD MMMM YYYY")}
      </Menu.Header>
    );
  }

  renderWeeks() {
    const currentMonthView = this.state.selectedMonth;
    const currentSelectedDay = this.state.selectedDay;
    const monthEvents = this.state.selectedMonthEvents;
    const chosen = this.state.chosen;
    let weeks = [];
    let done = false;
    let previousCurrentNextView = currentMonthView
      .clone()
      .startOf("month")
      .subtract(1, "d")
      .day("Sunday");
    let count = 0;
    let monthIndex = previousCurrentNextView.month();

    while (!done) {
      weeks.push(
        <Week
          previousCurrentNextView={previousCurrentNextView.clone()}
          currentMonthView={currentMonthView}
          monthEvents={monthEvents}
          selected={currentSelectedDay}
          select={(day) => this.select(day)}
          chosen={chosen}
        />
      );
      previousCurrentNextView.add(1, "w");
      done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
      monthIndex = previousCurrentNextView.month();
    }
    return weeks;
  }

  handleAdd() {
    const monthEvents = this.state.selectedMonthEvents;
    const currentSelectedDate = this.state.selectedDay;

    let newEvents = [];
    var eventTitle = prompt("Please enter a name for your event: ");

    switch (eventTitle) {
      case "":
        alert("Event name cannot be empty.");
        break;
      case null:
        alert("Changed your mind? You can add one later!");
        break;
      default:
        var newEvent = {
          title: eventTitle,
          date: currentSelectedDate,
          dynamic: true,
        };

        newEvents.push(newEvent);

        for (var i = 0; i < newEvents.length; i++) {
          monthEvents.push(newEvents[i]);
        }

        this.setState({
          selectedMonthEvents: monthEvents,
        });
        break;
    }
  }

  addEvent() {
    const currentSelectedDate = this.state.selectedDay;
    let isAfterDay = moment().startOf("day").subtract(1, "d");

    if (currentSelectedDate.isAfter(isAfterDay)) {
      this.handleAdd();
    }
  }
  success = () =>
    toast.success("Event Canceled!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  removeEvent(i) {
    const monthEvents = this.state.selectedMonthEvents.slice();
    const index = i;
    mixpanel.track("Renter Application Action", { sub: "Cancel Tour" });
    try {
      toursClient
        .mutate({
          mutation: updateTour,
          variables: {
            input: {
              tour: {
                id: index.id,
                status: "cancel",
                comment: "Cancel",
              },
            },
          },
        })
        .then((response) => {
          monthEvents.forEach((event) => {
            if (index === event) {
              monthEvents.splice(index, 1);
              return monthEvents;
            }
          });
          this.success();
          this.setState({
            selectedMonthEvents: monthEvents,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  }

  initializeEvents() {
    const monthEvents = this.state.selectedMonthEvents;
    let allEvents = [];

    for (var i = 0; i < allEvents.length; i++) {
      monthEvents.push(allEvents[i]);
    }

    this.setState({
      selectedMonthEvents: monthEvents,
    });
  }

  render() {
    const showEvents = this.state.showEvents;
    if (showEvents) {
      return (
        <Container fluid textAlign="center" className="main-calendar">
          <div className="calendar-header">
            <div className="top-left">
              <h5>Schedule</h5>
              <p>Here’s the list of your events</p>
            </div>
            <div className="top-right">
              <Menu attached="top" fluid className="calendar-header">
                <Menu.Item
                  onClick={this.showCalendar}
                  icon="calendar alternate outline"
                  position="left"
                  className="calendar-action"
                />
                {this.renderDayLabel()}
                {/* <Menu.Item
									onClick={this.addEvent}
									icon="add square"
									position="right"
									className="calendar-action"
								/> */}
              </Menu>
              {/* <button type="button" className="btn-primary">
								Add Schedule
							</button> */}
            </div>
          </div>

          <Segment
            attached="bottom"
            textAlign="center"
            className="calendar-body"
          >
            <Events
              selectedMonth={this.state.selectedMonth}
              selectedDay={this.state.selectedDay}
              selectedMonthEvents={this.state.selectedMonthEvents}
              removeEvent={(i) => this.removeEvent(i)}
            />
          </Segment>
        </Container>
      );
    } else {
      return (
        <Container textAlign="center" className="main-calendar">
          <div className="calendar-header">
            <div className="top-left">
              <h5>Schedule</h5>
              <p>Here’s the list of your events</p>
            </div>
            <div className="top-right">
              <Menu attached="top" fluid>
                <div className="date-txt">{this.renderMonthLabel()}</div>
                <div className="arrow-fnc">
                  <Menu.Item
                    onClick={this.previous}
                    icon="arrow left"
                    position="left"
                    className="calendar-action"
                  />

                  <Menu.Item
                    onClick={this.next}
                    icon="arrow right"
                    position="right"
                    className="calendar-action"
                  />
                </div>
              </Menu>
              {/* <button type="button" className="btn-primary" data-toggle="modal" data-target="#addschedulemodal">
								Add Schedule
							</button> */}
            </div>
          </div>
          <div
            class="modal fade add-schedule-modal"
            id="addschedulemodal"
            tabindex="-1"
            role="dialog"
            aria-labelledby=""
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Add Schedule
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src="/assets/img/close-modal.svg" alt="close"></img>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div className="form-group">
                      <label>Event Name</label>
                      <input type="text" className="form-control"></input>
                    </div>
                    <div className="form-rw">
                      <div className="form-group">
                        <label>Event Date</label>
                        <input type="text" className="form-control"></input>
                      </div>
                      <div className="form-group">
                        <label>Type</label>
                        <input type="text" className="form-control"></input>
                      </div>
                    </div>
                    <div className="form-rw">
                      <div className="form-group">
                        <label>Start Time</label>
                        <input type="text" className="form-control"></input>
                      </div>
                      <div className="form-group">
                        <label>End Time</label>
                        <input type="text" className="form-control"></input>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Invite</label>
                      <input type="text" className="form-control"></input>
                    </div>
                    <div className="form-group">
                      <label>Additional Notes</label>
                      <textarea type="text" className="form-control"></textarea>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary">
                    Add Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Segment
            attached="bottom"
            textAlign="center"
            className="calendar-body"
          >
            <Grid columns={7}>
              <DayNames />
              {this.renderWeeks()}
            </Grid>
          </Segment>
        </Container>
      );
    }
  }
}

class Events extends React.Component {
  render() {
    const currentSelectedDay = this.props.selectedDay;
    const monthEvents = this.props.selectedMonthEvents;
    const removeEvent = this.props.removeEvent;

    const monthEventsRendered = monthEvents.map((event, i) => {
      const descriptionArr = event.description.split("::");
      return (
        <Card fluid className="event-card">
          <Card.Content>
          {
            event.title !== "Maintenance Request" &&
            <Icon
              floated="right"
              name="trash alternate"
              onClick={() => removeEvent(event)}
              className="event-delete"
            />
          }
            <Card.Header>{event.title}</Card.Header>
            <Card.Meta>{event.time} </Card.Meta>
          </Card.Content>
          {event.title === "Maintenance Request" ? (
            <Card.Content
              description={`${descriptionArr[0]} by ${descriptionArr[1]} for a(n) ${descriptionArr[4]} issue at ${descriptionArr[2]}. Description: ${descriptionArr[5]}`}
            />
          ) : (
            event.title === "Tour Request" && (
              <Card.Content
                description={`${descriptionArr[1]} ${descriptionArr[0]} with ${descriptionArr[3]} at ${descriptionArr[2]}`}
              />
            )
          )}
        </Card>
      );
    });

    const dayEventsRendered = [];

    for (var i = 0; i < monthEventsRendered.length; i++) {
      if (monthEvents[i].date.isSame(currentSelectedDay, "day")) {
        dayEventsRendered.push(monthEventsRendered[i]);
      }
    }

    return (
      <Card.Group className="events-daily">{dayEventsRendered}</Card.Group>
    );
  }
}

class DayNames extends React.Component {
  render() {
    return (
      <>
        <Grid.Column className="calendar-dow">Sun</Grid.Column>
        <Grid.Column className="calendar-dow">Mon</Grid.Column>
        <Grid.Column className="calendar-dow">Tue</Grid.Column>
        <Grid.Column className="calendar-dow">Wed</Grid.Column>
        <Grid.Column className="calendar-dow">Thu</Grid.Column>
        <Grid.Column className="calendar-dow">Fri</Grid.Column>
        <Grid.Column className="calendar-dow">Sat</Grid.Column>
      </>
    );
  }
}

class Week extends React.Component {
  render() {
    let days = [];
    let date = this.props.previousCurrentNextView;
    let currentMonthView = this.props.currentMonthView;
    let selected = this.props.selected;
    let select = this.props.select;
    let monthEvents = this.props.monthEvents;
    let chosen = this.props.chosen;

    for (var i = 0; i < 7; i++) {
      var dayHasEvents = false;
      let dayEventCount = 0;

      for (var j = 0; j < monthEvents.length; j++) {
        if (monthEvents[j].date.isSame(date, "day")) {
          dayHasEvents = true;
          dayEventCount++;
        }
      }

      let day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === currentMonthView.month(),
        isBefore: date.isBefore(new Date(), "day"),
        isToday: date.isSame(new Date(), "day"),
        date: date,
        hasEvents: dayHasEvents,
        eventCount: dayEventCount,
      };

      days.push(
        <Day day={day} chosen={chosen} selected={selected} select={select} />
      );
      date = date.clone();
      date.add(1, "d");
    }
    return <>{days}</>;
  }
}

class Day extends React.Component {
  render() {
    let day = this.props.day;
    let selected = this.props.selected;
    let select = this.props.select;
    let chosen = this.props.chosen;
    const choose = (date) => {
      chosen = date;
    };
    return (
      <Grid.Column
        centered
        textAlign="center"
        className={
          "day" +
          (day.isToday ? " today" : "") +
          (day.date.isSame(chosen) ? " chosen" : "") +
          (day.isBefore ? " before" : "") +
          (day.isCurrentMonth ? "" : " different-month") +
          (day.date.isSame(selected) ? " selected" : "")
        }
        id={day.isToday ? "today" : ""}
        onClick={() => {
          select(day);
          choose(day.date);
        }}
      >
        <div className="day-number">{day.number}</div>
        {day.eventCount >= 1 ? (
          window.screen.width > 768 ? (
            <div className="event-label">{day.eventCount} Event</div>
          ) : (
            <div>
              <Icon disabled name="sticky note" />
            </div>
          )
        ) : (
          <div>&nbsp;</div>
        )}
      </Grid.Column>
    );
  }
}

export default Schedule;
