// @flow
import { toast } from "react-toastify";
import Cookie from "js-cookie";
import {
  signInWithEmailAndPassword,
  getIdTokenResult,
  onAuthStateChanged,
  onIdTokenChanged,
} from "firebase/auth";
import { getToken } from "firebase/messaging";
import config from "../../config";
import { getAuthToken, getClient, logoutAction } from ".";
import {
  getEmitter,
  TOKEN,
  RELOAD,
  LOGIN,
  SESSION_TIMEOUT_THRESHOLD,
  USER_EVENT,
  USER_ROLE_EVENT,
  RENTER,
  MANAGER,
  CURRENT_TOKEN,
  SERVICEPROFESSIONAL,
  PERSON_URL,
  EXPIRE_TIME,
} from "../../utils";
import { personGql, FCMAttachDevice } from "../../store/person/person";
import {
  auth,
  createToken,
  messaging,
} from "../../containers/Auth/Firebase/index";
import { vapidKey } from "../../config/firebase";

let sessionTimeout = null;
export const registerAuthToken = (token) => {
  Cookie.set(TOKEN, token, {
    domain: config.domain,
  });
};

const toastErrorOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const loginAction = ({ email, password }, client) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      return auth.currentUser;
    })
    .catch((e) => {
      console.log(e);
      if (e.code === "auth/too-many-requests") {
        toast.error(
          "Too many attempts please check back in a few minutes.",
          toastErrorOptions
        );
      } else {
        toast.error(
          "User e-mail id or password is incorrect.",
          toastErrorOptions
        );
      }
      console.log("User e-mail id or password is incorrect.");
    });
};

const setSessionTimeout = (duration, client) => {
  clearTimeout(sessionTimeout);
  sessionTimeout = setTimeout(
    refreshToken,
    (duration - SESSION_TIMEOUT_THRESHOLD) * 1000,
    { client }
  );
};

const refreshToken = ({ client }) => {
  const session = getAuthToken();
  if (!session) {
    return Promise.reject();
  }
  onRequestSuccess(client);
};

export const handleMangerLogin = (client) => {
  return getIdTokenResult(auth.currentUser, auth)
    .then(async (idTokenResult) => {
      if (
        idTokenResult.claims.renter &&
        idTokenResult.claims.email_verified === true
      ) {
        toast.success("User logged in successfully.", toastErrorOptions);
        onRequestSuccess(client);
        return true;
      }
      if (
        idTokenResult.claims.renter &&
        idTokenResult.claims.email_verified === false
      ) {
        toast.warning(
          "Please make sure to verify your email.",
          toastErrorOptions
        );
        return "Verify";
      } else return false;
    })
    .catch(() => {
      return false;
    });
};

const getTokens = async (setTokenFound) => {
  const personMainClient = getClient(PERSON_URL);
  const currentToken = await createToken();
  try {
    await personMainClient.mutate({
      mutation: FCMAttachDevice,
      variables: {
        input: {
          device: {
            deviceType: "WEB",
            deviceToken: currentToken,
          },
        },
      },
    });
    const eventEmit = getEmitter();
    eventEmit.emit(CURRENT_TOKEN, currentToken);
  } catch (e) {}
};

// const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
// });

export const onRequestSuccess = async (client, from = LOGIN) => {
  const time = "3600";
  if (from === LOGIN || (from === RELOAD && getAuthToken())) {
    const eventEmit = getEmitter();
    if (auth.currentUser) {
      try {
        // getIdToken to know user loggedin or not
        const idTokenResult = await getIdTokenResult(auth.currentUser);
        if (
          idTokenResult.claims.renter &&
          idTokenResult.claims.email_verified
        ) {
          onAuthStateChanged(auth, async function (user) {
            // if user logged in
            if (user) {
              // get token
              const token = await auth.currentUser.getIdToken(true);

              // set token cookie
              registerAuthToken(token);
              // set role cookies
              getUserRoles();

              // apollo client reset cache
              client.resetStore();

              // expire time is current time + 3 hours more
              Cookie.set(EXPIRE_TIME, new Date().getTime() + 3 * 60 * 60 * 1000, {
                domain: config.domain,
              });
              setSessionTimeout(time, client);

              if (from === LOGIN) {
                const personMainClient = getClient(PERSON_URL);
                const res = await personMainClient.query({
                  query: personGql,
                });

                eventEmit.emit(USER_EVENT, { data: res.data.person });
                await getTokens();
                // onMessageListener().then(payload => {
                //   const eventEmit = getEmitter()
                //   eventEmit.emit(NOTIFICATION, payload)
                // }).catch(err => console.log('failed: ', err));
              }
            }
          });
        }
      } catch (error) {
        console.log("onRequestSuccess", error);
        return false;
      }
    } else {
      console.log("Current user unavailable.");
      logoutAction(client).then(() =>
        setTimeout(() => {
          window.location.href = "/";
        }, 1000)
      );
    }
  }
};

export const getUserRoles = async () => {
  try {
    const idTokenResult = await getIdTokenResult(auth.currentUser);
    if (idTokenResult.claims) {
      const eventEmit = getEmitter();
      Cookie.set(RENTER, idTokenResult.claims.renter, {
        domain: config.domain,
      });
      Cookie.set(MANAGER, idTokenResult.claims.manager, {
        domain: config.domain,
      });
      Cookie.set(
        SERVICEPROFESSIONAL,
        idTokenResult.claims.serviceProfessional,
        {
          domain: config.domain,
        }
      );
      eventEmit.emit(USER_ROLE_EVENT);
    }
  } catch (error) {
    console.log(error);
  }
};

export default { loginAction, onRequestSuccess };