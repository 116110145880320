import React from "react";
import { PORTALS } from "../../utils/constants";

import "./ApplicationsEmpty.scss";
export const TripsEmpty = () => {
  return (
    <div className="applications-empty__container">
      <div className="card">
        <div className="card-body">
          <div className="applications-empty__grid">
            <img src="/assets/img/dashboard/no-data.svg" alt="no-data"></img>
            <div className="applications-empty--message">
              <h5>You have not booked any trips yet!</h5>
            </div>
            {/* eslint-disable */}
            <p>
              Check out our latest offers on vacation rentals under the{" "}
              <a href="#">My Leasera</a> section and book a trip now!
            </p>
            <p>
              You can use your earned <a href="#">points</a> and find
              unparalleled discounts. Wherever you're headed, we have you
              covered.
            </p>
            {/* eslint-enable */}
            {/* <div className="applications-empty--icon">
              <i className="fas fa-map"></i>
            </div> */}
            <div className="applications-empty--link">
              <a className="btn-outline-primary" href={PORTALS.landing}>
                Search for great future trip locations
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
