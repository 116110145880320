import gql from "graphql-tag";

export const createPersonPaymentGql = gql`
  mutation createPaymentInstrument($input: Bank!) {
    createPaymentInstrument(bank: $input) {
      response
    }
  }
`;
// export const createPersonPaymentGql = gql`
//   mutation createPersonPayment($input: CreatePersonPaymentInput!) {
//     createPersonPayment(input: $input) {
//       response
//     }
//   }
// `

export const submitTAGql = gql`
  mutation tenantAlertRequest($tenantAlert: TenantAlertRequest!) {
    tenantAlertRequest(tenantAlert: $tenantAlert) {
      response
    }
  }
`;

export const paymentG = gql`
  query ($leaseId: String, $latest: Boolean) {
    payments(leaseId: $leaseId, latest: $latest) {
      edges {
        node {
          Id
          Location {
            id
            name
          }
          Invoice {
            reference
          }
          status
          amount
          postedDate
          InvoiceId
        }
      }
    }
  }
`;

export const personPaymentsGql = gql`
  query {
    paymentInstruments {
      edges {
        node {
          id
          cardType
          heartlandToken
          accountId
          cardType
          cardReferenceNumber
          firstName
          lastFour
          expMonth
          expYear
          zip
          autopay
          bankName
          bankAccountNumber
          bankRoutingNumber
          metaData
          type
        }
      }
    }
  }
`;
export const personMethodsGql = gql`
  query {
    paymentMethods
  }
`;
export const personScheduleGql = gql`
  query {
    schedules
  }
`;

export const currentBalanceGql = gql`
  query {
    currentBalance
  }
`;

export const deletePersonPaymentGql = gql`
  mutation deletePaymentMethod($input: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      response
    }
  }
`;
export const deletePersonScheduleGql = gql`
  mutation deleteSchedule($input: DeleteScheduleInput!) {
    deleteSchedule(input: $input) {
      response
    }
  }
`;
export const savedInfoPaymentMutationGql = gql`
  mutation chargePaymentMethod($paymentMethod: ChargePaymentMethodInput!) {
    chargePaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;
export const singleInfoPaymentMutationACHGql = gql`
  mutation chargePaymentMethod($paymentMethod: ChargePaymentMethodInput!) {
    chargePaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;
export const singleInfoPaymentMutationCCGql = gql`
  mutation chargePaymentMethod($paymentMethod: ChargePaymentMethodInput!) {
    chargePaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;

export const personPaymentActionCCGql = gql`
  mutation createPaymentMethod($paymentMethod: CreatePaymentMethodInput!) {
    createPaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;

export const personPaymentActionACHGql = gql`
  mutation createPaymentMethod($paymentMethod: CreatePaymentMethodInput!) {
    createPaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;

export const personCaptcha = gql`
  mutation VerificationCaptcha($verification: CaptchaVerificationInput!) {
    captchaVerification(verification: $verification) {
      response
    }
  }
`;

export const personPaymentScheduleGql = gql`
  mutation createSchedule($schedule: CreateScheduleInput!) {
    createSchedule(schedule: $schedule) {
      response
    }
  }
`;

export const personPaymentScheduleDeleteGql = gql`
  mutation deleteSchedule($schedule: DeleteScheduleInput!) {
    deleteSchedule(schedule: $schedule) {
      response
    }
  }
`;

export const personPaymentDeleteGql = gql`
  mutation deletePaymentMethod($paymentMethod: DeletePaymentMethodInput!) {
    deletePaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;

export const personPaymentActionGql = gql`
  mutation createPaymentMethod($paymentMethod: CreatePaymentMethodInput!) {
    createPaymentMethod(creditCard: $paymentMethod) {
      response
    }
  }
`;

export const lastTwoPayments = gql`
  query ($leaseIds: [String!]) {
    lastTwoPayments(leaseIds: $leaseIds)
  }
`;

// export const maintenanceGql = gql`
// query maintenanceRequest($role: String!, $status: [String!]) {
//   maintenanceRequest(role: $role, status:$status) {
//     edges {
//       node
//         ${requestfields}
//     }
//   }
// }
// `

export const updatePaymentActionGql = gql`
  mutation updatePaymentInstrument($input: UpdateCreditCard!) {
    updatePaymentInstrument(creditCard: $input) {
      response
    }
  }
`;

export const updatePaymentMethodGql = gql`
  mutation updatePaymentMethod($paymentMethod: UpdatePaymentMethodInput!) {
    updatePaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;

export const personPayGql = gql`
  mutation paymentActionRenter($input: PaymentActionRenterInput!) {
    paymentActionRenter(paymentActionRenter: $input) {
      response
      payment
      cashReceipt
    }
  }
`;
export const personPayAllGql = gql`
  mutation paymentActionRenterPayFull(
    $input: PaymentActionRenterPayFullInput!
  ) {
    paymentActionRenterPayFull(paymentActionRenterPayFull: $input) {
      response
    }
  }
`;
export const PayBcGql = gql`
  mutation paymentActionRenterPayBc($input: PaymentActionRenterPayBcInput!) {
    paymentActionRenterPayBc(paymentActionRenterPayBc: $input) {
      response
    }
  }
`;

export const updatePersonPaymentGql = gql`
  mutation updatePersonPayment($input: UpdatePersonPaymentInput!) {
    updatePersonPayment(input: $input) {
      response {
        autopay
        autopayAmount
      }
    }
  }
`;

export const ConvenienceFees = gql`
  query ($amount: Float, $points: Float, $concessionsAmount: Float) {
    convenienceFees(amount: $amount, points: $points, concessionsAmount:$concessionsAmount) {
      type
      chargeType
      amount
      currency
      convenienceAmount
      totalAmount
    }
  }
`;

export const ConcessionsAmountLease = gql`
  query concessionsAmountLease($leaseId: String!) {
    concessionsAmountLease(leaseId: $leaseId) {
      edges {
        node {
          id
          locationId
          leaseId
          renterPersonId
          concessionsAmount
          description
        }
      }
    }
  }
`;
export const chargePaymentMethodDonations= gql`
  mutation chargePaymentMethodDonations($paymentMethod: ChargePaymentMethodDonationsInput!) {
    chargePaymentMethodDonations(paymentMethod: $paymentMethod) {
      response 
    }
  }
`;

