import React from "react";
import { Button, Modal } from "semantic-ui-react";
import "./MaintenanceContactModal.scss";

const ContactStyling = {
  position: "fixed",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
};

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

const MaintenanceContactModal = ({ isOpen, phoneNumber, email }) => {
  return (
    <>
      <Modal
        size="mini"
        open={isOpen}
        onClose={() => false}
        style={ContactStyling}
      >
        <Modal.Header>Property Contact Information</Modal.Header>
        <Modal.Content>
          <p>
            The below are the property's main forms of contact (Click to perform
            an action):
          </p>
          <a className="modal-link" href={`tel:+1${phoneNumber}`}>
            <i aria-hidden="true" class="mail icon"></i>
            {formatPhoneNumber(phoneNumber)}
          </a>
          <br />
          <a className="modal-link" href={`mailto:${email}`}>
            <i aria-hidden="true" class="phone icon"></i>
            {email}
          </a>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => !isOpen}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default MaintenanceContactModal;
