import React from "react";
import { Link } from "react-router-dom";
import { MobileView } from "react-device-detect";
import { Icon } from "semantic-ui-react";
import Actions from "../Actions";
import { useSelector } from "react-redux";
import { CHAT_URL_IMAGE_PATH } from "../../../../utils";

const ChatHeader = ({
  id,
  avatar,
  roomTitle,
  description,
  label,
  chatRoomId,
  setVisibleModal,
  setModalData,
}) => {
  const { chatRoomDetails } = useSelector((store) => store.chat);
  const contactData = chatRoomDetails[chatRoomId];

  return (
    <div className="chat-header">
      <MobileView>
        <Link to="/messages/chat-rooms" className="back">
          <Icon name="arrow left" color="#4f5b60" />
        </Link>
      </MobileView>
      <div className="avatar">
        <img src={`${CHAT_URL_IMAGE_PATH}${contactData?.picture || avatar}`} alt="img" />
      </div>
      <div className="room-info">
        <div className="room-title">{contactData?.name || roomTitle}</div>
        <div className="description">{description}</div>
      </div>
      <span className="chat-contact-label" type={contactData?.role || label}>
        {contactData?.role || label}
      </span>
      <span className="search">
        <Icon icon="search" />
      </span>
      {/* <Actions
          style={{ right: 0 }}
          options={[
            {
              onClick: () => {
                setVisibleModal(true);
                setModalData(id);
              },
              label: "Archive Conversation",
            },
          ]}
        /> */}
    </div>
  );
};

export default ChatHeader;
