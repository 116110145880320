import gql from "graphql-tag";
import { propertyPlace, locationAddressKey } from "../utils/propertiesKeys";

export const propertiesGql = gql`
  query location($filter: LocationInput!) {
    location(filter: $filter) {
      edges {
        node {
          photos
          description
          totalOccupied
          totalBeds
          totalBaths
          totalPrices
          totalSizes
          walkScore
          locationId
          fees
          filteredUnits
          amenities
          depositRules
          yearBuilt
          services
          petInformation
          status
          totalUnits
          contact
          addresses {
            city
            state
            zip
            streetOne
            country
          }
          id
          googlePlaceId
          location
          name
          petsPermitted
        }
      }
    }
  }
`;
export const propertiesGoogleGql = gql`
  query location($filter: LocationInput!) {
    location(filter: $filter) {
      edges {
        node {
          id
          name
          location
          addresses {
            city
            state
            zip
            streetOne
            country
          }
          photos
          contact
        }
      }
    }
  }
`;

export const primaryLocationAmenitiesGql = gql`
  query PrimaryLocation($propertyId: String) {
    primaryLocation(propertyId: $propertyId) {
      edges {
        node {
          generalAmenities
        }
      }
    }
  }
`;
export const amenitiesGql = gql`
  query {
    amenities {
      edges {
        node {
          id
          name
          dbId
        }
      }
    }
  }
`;

export const primaryLocation = gql`
query primaryLocation($propertyId: String!){
  primaryLocation(propertyId: $propertyId){
    edges {
      node {
        id
        customId
        photos
        generalAmenities
        ${locationAddressKey}
        walkScore {
          walk
          transit
          bike
        }
        propertyManagers {
          firstName
          lastName

        }
        properties {
          ndbId
          ${propertyPlace}
          manualBackgroundCreditOk
          place {
            bedrooms
            bathrooms
            size
          }
          location {
            state
            streetOne
            city
            unitNum
          }
        }
        applicationInformation,
      }
    }
  }
}`;

export const primaryLocationForApp = gql`
  query primaryLocation($primaryLocationId: String!) {
    primaryLocation(primaryLocationId: $primaryLocationId) {
      edges {
        node {
          id
          customId
          applicationInformation
        }
      }
    }
  }
`;

export const TourSingleLocation = gql`
  query tours {
    tours {
      edges {
        node {
          id
          connectionInfo
          name
          location
          primaryLocation {
            name
            addresses {
              city
              state
              zip
              streetOne
              country
            }
          }
          lead {
            id
            firstName
          }
          createdBy {
            firstName
          }
          status
          date
          time
        }
      }
    }
  }
`;
export const AllTourLocation = gql`
  query allTours($location: String!, $fromDate: String!, $toDate: String!) {
    allTours(location: $location, fromDate: $fromDate, toDate: $toDate) {
      edges {
        node {
          duration
          date
          time
          status
        }
      }
    }
  }
`;

export const updateTour = gql`
  mutation updateTour($input: UpdateLeadTourInput!) {
    updateTour(input: $input) {
      response
      tour {
        id
        name
        status
        date
        time
      }
    }
  }
`;

export const createTour = gql`
  mutation createTour($input: CreateLeadTourInput!) {
    createTour(input: $input) {
      response
    }
  }
`;

export const TourTypes = gql`
  query TourTypes($location: String!) {
    tourTypes(location: $location) {
      edges {
        node {
          code
          id
          duration
          connectionInfo
          name
          selfBooking
          createdBy {
            firstName
            lastName
          }
          created
          updated
        }
      }
    }
  }
`;

export const unitType = gql`
  query unitType($locationId: String!) {
    unitType(locationId: $locationId) {
      edges {
        node {
          id
          type
          bedrooms
          bathrooms
        }
      }
    }
  }
`;

export const unitInfo = gql`
  query unit($locationId: String!, $unitId: String!) {
    unit(locationId: $locationId, unitId: $unitId) {
      edges {
        node {
          id
          unitTypesId
          number
          level
          unitType {
            id
            type
            bathrooms
            bedrooms
          }
        }
      }
    }
  }
`;

export const units = gql`
  query units($locationId: String!, $unitTypesId: String!) {
    units(locationId: $locationId, unitTypesId: $unitTypesId) {
      edges {
        node {
          id
          level
          unitTypesId
          number
        }
      }
    }
  }
`;

export const unitsGql = gql`
  query unit($filter: UnitInput!) {
    unit(filter: $filter) {
      edges {
        node {
          id
          locationId
          unitTypesId
          photos
          amenities
          floor
          building
          number
          description
          bathrooms
          bedrooms
          sqft
          price
          petsPermitted
          isDelete
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const fetchBackgroundInfo = gql`
  query locationUtils($locationId: String!) {
    locationUtils(locationId: $locationId) {
      edges {
        node {
          applicationInformation
        }
      }
    }
  }
`;

export const createSenderRequest = gql`
  mutation SendRenterLocationRequest($input: RenterRequestInput!) {
    renterRequest(input: $input) {
      response
    }
  }
`;
