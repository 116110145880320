import React, { useState } from "react";
import { Button, Modal, Header } from "semantic-ui-react";

export default function LeadAlreadyCreated(props) {
  const [open, setOpen] = useState(true);
  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          Lead Already Created
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description className="w-100">
          <p>
            The lead for this unit and unit type is already created. You can
            further schedule a tour or create an application now.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Cancel"
          onClick={() => props.closeModal()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}
