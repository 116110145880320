import gql from "graphql-tag";

export const createRenterWaitlistGql = gql`
  mutation createRenterWaitList($input: CreateRenterLeadInput!) {
    renterCreateLead(input: $input) {
      response
      isLeadExistsWithUnit
    }
  }
`;
